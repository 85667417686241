export default {
    ID_TOKEN: 'idToken',
    USER_ROLE: 'userRole',
    CLAIMS: 'claims',
    DROPDOWN_STYLES: {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'grey' : 'white',
        }),
        control: (base: any) => ({
            ...base,
            'minHeight': '46px',
        })
    },
    RECURRENCE_OPTIONS: [
        {
            value: 'daily',
            label: 'Daily'
        },
        {
            value: 'weekly',
            label: 'Weekly'
        },
        {
            value: 'monthly',
            label: 'Monthly'
        },
        {
            value: 'yearly',
            label: 'Yearly'
        }
    ],
    VIEW_TYPE_OPTIONS: [
        {
            value: 'view_by_store',
            label: 'View By Store',
        },
        {
            value: 'view_by_cluster',
            label: 'View By Cluster',
        }
    ],
    PLANO_GRAM_TYPE_OPTIONS: [
        {
            value: 'product',
            label: 'Report By Product Category',
        },
        {
            value: 'compliance',
            label: 'Report By Compliance KPIs',
        }
    ]
}

import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import cogoToast from 'cogo-toast';

import { REACT_APP_API_ENDPOINT } from '../../lib/getuserdetails';
import { getClustersByClient, getStoresByClient } from '../../lib/apiWrappers';
import secureStorage from '../../lib/localStorage';
import constants from '../../lib/constants';

import './auditschedules.scss';

const AuditSchedules: React.FC = () => {
    const history = useHistory();
    const [userRole, setUserRole] = useState<string | undefined>('associate');
    const [idToken, setIdToken] = useState<string | null>(null);
    const [auditSchedulesLoading, setAuditSchedulesLoading] = useState(false);
    const [auditSchedulesList, setAuditSchedulesList] = useState<any[]>([]);
    const [viewByStore, setViewByStore] = useState(true);
    const [viewByOptionSelected, setViewByOptionSelected] = useState<{ value: string, label: string }>({
        value: '',
        label: 'Loading options...'
    });
    const [viewByOptions, setViewByOptions] = useState<{ value: string, label: string }[]>([]);
    const handleClickChangeViewBy = () => {
        setViewByStore(!viewByStore);
    };

    const getUserDetails = () => {
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);
        if (!userToken || !userRole) {
            return;
        }

        setUserRole(userRole);
        setIdToken(userToken);
    };

    const getAuditSchedules = async () => {
        if (!idToken || userRole !== 'headquarter') {
            return;
        }
        if (viewByOptionSelected && viewByOptionSelected.value !== "") {
            let auditSchedulesEndpoint;
            setAuditSchedulesLoading(true);

            if (viewByStore) {
                auditSchedulesEndpoint = `${REACT_APP_API_ENDPOINT}/store/${viewByOptionSelected.value}/schedule-tasks`;
            } else {
                auditSchedulesEndpoint = `${REACT_APP_API_ENDPOINT}/cluster/${viewByOptionSelected.value}/schedule-tasks`;
            }
            try {
                const response = await fetch(auditSchedulesEndpoint, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                const responseJson = await response.json();
                if (responseJson.success) {
                    setAuditSchedulesList(responseJson.data);
                }
            } catch (e) {
                const response = await fetch(`${REACT_APP_API_ENDPOINT}/schedule-tasks`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                });
                const responseJson = await response.json();
                if (responseJson.success) {
                    setAuditSchedulesList(responseJson.data);
                }
            }
            setAuditSchedulesLoading(false);
        }
    };

    const loadOptions = async () => {
        if (!idToken || userRole !== 'headquarter') {
            return;
        }
        const claimsData = secureStorage.getItem(constants.CLAIMS);
        const clientId = claimsData ?.app_metadata ?._client ?? undefined;
        if (!clientId) {
            cogoToast.error("You are not associated with any company, please contact support");
            history.push("/home");
            return;
        }
        let viewOptions: any = [];
        if (viewByStore) {
            const stores = await getStoresByClient(clientId, idToken);
            stores.forEach(store => {
                if (store) {
                    viewOptions.push({
                        value: store._id,
                        label: store.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No stores could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        } else {
            const clusters = await getClustersByClient(clientId, idToken);
            clusters.forEach(cluster => {
                if (cluster) {
                    viewOptions.push({
                        value: cluster._id,
                        label: cluster.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No Cluster could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        }
        setViewByOptions(viewOptions);
        setViewByOptionSelected(viewOptions[0]);
    };

    useEffect(() => {
        getUserDetails()
    }, []);

    useEffect(() => {
        getAuditSchedules();
    }, [viewByOptionSelected]);

    useEffect(() => {
        loadOptions();
    }, [viewByStore]);
    useEffect(() => {
        loadOptions();
    }, [userRole]);

    if (auditSchedulesLoading) {
        return (
            <div className="content content--auditschedules auditschedules auditschedules--loading">
                <div className="loadingSpinner" />
            </div>
        )
    }
    return (
        <Fragment>
            <div className="content content--auditschedules auditschedules">
                <div className="tasks__content tasks__viewBy">
                    <div className="tasks__viewBy__selector switch-field">
                        <input
                            type="radio"
                            id="radio-one"
                            name="switch-one"
                            value="View by store"
                            checked={viewByStore}
                            onChange={handleClickChangeViewBy}
                        />
                        <label htmlFor="radio-one">View by store</label>
                        <input
                            type="radio"
                            id="radio-two"
                            name="switch-one"
                            value="View by cluster"
                            checked={!(viewByStore)}
                            onChange={handleClickChangeViewBy}
                        />
                        <label htmlFor="radio-two">View by cluster</label>
                    </div>
                    <div className="tasks__viewBy__dropdown">
                        <Select
                            value={viewByOptionSelected}
                            styles={constants.DROPDOWN_STYLES}
                            onChange={(selectedOption: any) => {
                                setViewByOptionSelected(selectedOption)
                            }}
                            options={viewByOptions}
                        />
                    </div>
                </div>
                <div className="content__content auditschedules__content">
                    {
                        (auditSchedulesList.length === 0) ?
                            <div className="auditschedules--empty">
                                No Audit schedules found
                            </div>
                            :
                            auditSchedulesList.map(auditschedule => {
                                return (
                                    <div className="tasks__item" key={auditschedule._id}>
                                        <a className="tasks__item__link"
                                           onClick={() => history.push(`/auditschedule/${auditschedule._id}`)}>
                                            <div className="tasks__item__title">
                                                {auditschedule?.title}
                                            </div>
                                            <div className="tasks__item__description">
                                                Recurring {auditschedule.recurrence}
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <div className='content__cta tasks__cta'>
                <div className='tasks__cta__item'>
                    <button
                        className='tasks__cta__button btn--primary btn'
                        onClick={() => {
                            history.push('/createauditschedule');
                        }}
                    >
                        Add new Audit
                    </button>
                </div>
            </div>
        </Fragment>
    )
};

export default AuditSchedules;


import React, { Fragment, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import './reportselectpre.scss';
import CardGiftCardIcon from '@material-ui/icons/CardGiftcard';

import { useAuth0 } from '../../lib/auth';
import { namespace } from "../../lib/getuserdetails";
import { Button } from '../../components/button/button-container';
import PlanogramIcon from '../../images/planogram-icon.png';

const ReportSelectPre: React.FC = () => {
    const history = useHistory();
    const {
        getIdTokenClaims
    } = useAuth0();
    const initiateReportSelect = async () => {
        const claims: any = await getIdTokenClaims();
        const claimsData = claims[`${namespace}claims/`];
        const userRoles = claimsData ?.roles ?? [];
        // get the user role
        // if more than one role, select the one with highest authority
        let userFinalRole = undefined;
        // TODO: get a better way to store the possible roles
        for (let role of ['associate', 'manager', 'headquarter']) {
            if (userRoles.includes(role)) {
                userFinalRole = role;
            }
        }
        if (userFinalRole === "associate") {
            history.push("/home");
            return;
        }
    }
    useEffect(() => {
        initiateReportSelect();
    }, []);

    return (
        <div className="content content--reportselect reportselect">
            <div className="content__content reportselect__content">
                <div className="section payment">
                    <div className="section__content payment__content">
                        <div className='home__cta'>
                            <Button
                                className='btn'
                                onClick={() => history.push('/report/planogram')}
                                buttonText={
                                    <Fragment>
                                        <img src={PlanogramIcon} alt={'plangram icon'} style={{ width: 40 }} />
                                        <p>Planogram compliance</p>
                                    </Fragment>
                                }
                            />
                        </div>
                        <div className='home__cta'>
                            <Button
                                className='btn'
                                onClick={() => history.push('/report/promotion')}
                                buttonText={
                                    <Fragment>
                                        <CardGiftCardIcon />
                                        <p>Promotion compliance</p>
                                    </Fragment>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportSelectPre;
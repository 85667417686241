import moment from 'moment';
import EXIF from 'exif-js';

const getUTCTime =  function (localTime: string) {
    return moment(localTime, "HH:mm:ss").utc().format("HH:mm:ss")
};

const getCurrentTimeZone = function() {
    // Change this
    return Intl.DateTimeFormat().resolvedOptions().timeZone
};

const getLocalTime = function (utcTime: string) {
    return moment.utc(utcTime, "HH:mm:ss").local().format('HH:mm:ss')
};

const checkImageMetaData = (files: FileList) => {
    return new Promise((resolve, reject) => {
        if (files[0] && files[0].name) {
            // @ts-ignore
            EXIF.getData(files[0], function () {
                // @ts-ignore
                const exifData = EXIF.pretty(this);
                if (exifData) {
                    // @ts-ignore
                    const originalTime = EXIF.getTag(this, "DateTimeOriginal");
                    const originalTimeStr = originalTime.split(" ");
                    const dateStr = originalTimeStr[0].replace(/:/g, "-");
                    const properDateStr = dateStr + " " + originalTimeStr[1];
                    const now = moment.utc(new Date());
                    const diff = now.diff(moment.utc(new Date(properDateStr)), 'days');
                    if (diff === 0) {
                        return resolve(true);
                    } else {
                        return reject(new Error('Unsupported File'));
                    }
                } else {
                    return reject(new Error('Unsupported File'));
                }
            });
        } else {
            return reject(new Error('Unsupported File'));
        }
    });
};

export {
    checkImageMetaData,
    getUTCTime,
    getCurrentTimeZone,
    getLocalTime
};
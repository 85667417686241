import React, { useState, useEffect, Fragment, FC } from 'react';
import { useHistory } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'react-circular-progressbar/dist/styles.css';

import rightIcon from '../../images/right.png';
import { useAuth0 } from '../../lib/auth';
import { getReport } from '../../lib/apiWrappers';

const ReportFilter: FC = () => {
    const history = useHistory();
    const { getIdTokenClaims } = useAuth0();
    interface IState {
        params: Object | any;
    }
    const state: IState | any = history.location.state;
    const [dateRange, setdateRange] = useState({
        startDate: moment(state.params.formatedStartDate),
        endDate: moment(state.params.formatedEndDate)
    });
    const [dateYear, setDateYear] = useState({
        start: '',
        end: '',
        year: '',
    });
    const [isResult, setIsResult] = useState(false);
    const [loadingResult, setLoadingResult] = useState(true);
    const [secondDatepickerFocus, setSecondDatepickerFocusFocus] = useState(
        null
    );
    const { startDate, endDate } = dateRange;
    const [reportType, setReportType] = useState('planogram');
    const [reportFilter, setReportFilter] = useState(state.params.reportFilter);
    const [secondaryReportFilter, setSecondaryReportFilter] = useState(
        undefined
    );
    const [reportData, setReportData] = useState<any>(null);
    const [secondaryReportData, setSecondaryReportData] = useState<any>(null);
    const [viewByStore, setViewByStore] = useState(true);
    const [viewByOptionSelected, setViewByOptionSelected] = useState<{
        value: string;
        label: string;
    }>({ value: '', label: 'Loading options...' });

    const [
        selectedPlanogramTypeOption,
        setSelectedPlanogramTypeOption,
    ] = useState<{ value: string; label: string }>({
        value: 'product',
        label: 'Report By Product Category',
    });

    const handleOnDateChange = ({ startDate, endDate }: any) =>
        setdateRange({ startDate, endDate });
    const getCurrentReport = async (
        storeOrClusterId: string,
        viewByStore: boolean,
        reportType: string | undefined,
        reportFilter: string | undefined,
        formatedStartDate: string,
        formatedEndDate: string,
        isSecondary?: Boolean | undefined
    ) => {
        setLoadingResult(true);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const curentReportJson: any = await getReport(
            storeOrClusterId,
            viewByStore,
            reportType,
            reportFilter,
            formatedStartDate,
            formatedEndDate,
            idToken
        );
        if (!curentReportJson.success) {
            cogoToast.error('Report could not be retrieved');
            if (isSecondary) {
                setSecondaryReportData(null);
            } else {
                setReportData(null);
            }
            setLoadingResult(false);
            return;
        }
        if (!curentReportJson.data || !curentReportJson.data.overall) {
            cogoToast.warn('No report found');
            if (isSecondary) {
                setSecondaryReportData(null);
            } else {
                setReportData(null);
            }
            setLoadingResult(false);
            return;
        }
        if (isSecondary) {
            setSecondaryReportData(curentReportJson.data);
        } else {
            setReportData(curentReportJson.data);
        }
        setLoadingResult(false);
        return;
    };
    const getDateYear = (dateRange: any) => {
        let start = moment(dateRange.startDate).format('DD MMM');
        let end = moment(dateRange.endDate).format('DD MMM');
        let year = moment(dateRange.end).format('YYYY');
        setDateYear({
            start,
            end,
            year,
        });
    };

    useEffect(() => {
        const startDate = dateRange.startDate;
        const endDate = dateRange.endDate;
        if (startDate && endDate) {
            getDateYear(dateRange);
            const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formatedEndDate = moment(endDate).format('YYYY-MM-DD');
            if (window.screen && window.screen.width > 800) {
                if (reportType === 'promotion') {
                    getCurrentReport(
                        viewByOptionSelected.value,
                        viewByStore,
                        reportType,
                        reportFilter,
                        formatedStartDate,
                        formatedEndDate
                    );
                } else {
                    getCurrentReport(
                        viewByOptionSelected.value,
                        viewByStore,
                        'product',
                        reportFilter,
                        formatedStartDate,
                        formatedEndDate
                    );
                    getCurrentReport(
                        viewByOptionSelected.value,
                        viewByStore,
                        'compliance',
                        secondaryReportFilter,
                        formatedStartDate,
                        formatedEndDate,
                        true
                    );
                }
            } else {
                setIsResult(true);
                getCurrentReport(
                    state.params.viewByOptionSelected,
                    state.params.viewByStore,
                    state.params.reportView,
                    state.params.reportFilter,
                    formatedStartDate,
                    formatedEndDate
                );
            }
        }
        return;
    }, [dateRange]);
    const getPrimaryReportDivStyle = () => {
        if (reportType === 'promotion') {
            return {};
        }
        if (window.screen && window.screen.width > 800) {
            return { width: '50%', display: 'inline-block' };
        } else {
            return {};
        }
    };

    const getSecondaryReportDivStyle = () => {
        if (
            reportType === 'planogram' &&
            window.screen &&
            window.screen.width > 800
        ) {
            return { width: '50%', display: 'inline-block' };
        } else {
            return { display: 'none' };
        }
    };
    const getSVGDef = (overallScore: any) => {
        if (overallScore >= 75) {
            return '#excellent';
        } else if (overallScore > 50 && overallScore < 75) {
            return '#medium';
        } else {
            return '#low';
        }
    };
    const getRowstyle = (key: any) => {
        if (key.percentage >= 75) {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#56d41b'
            };
        } else if (key.percentage > 50 && key.percentage < 75) {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#d38614'
            };
        } else {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#e81111'
            };
        }
    };
    // @ts-ignore
    return (
        <div className='reports'>
            <svg style={{ position: 'absolute' }}>
                <defs>
                    <linearGradient
                        id='excellent'
                        x1='0%'
                        y1='0%'
                        x2='100%'
                        y2='0%'
                    >
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='50%' stopColor='#437ee7' />
                        <stop offset='100%' stopColor='#4586ec' />
                    </linearGradient>
                    <linearGradient
                        id='medium'
                        x1='0%'
                        y1='0%'
                        x2='100%'
                        y2='0%'
                    >
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='50%' stopColor='#4167d9' />
                        <stop offset='100%' stopColor='#4586ec' />
                    </linearGradient>
                    <linearGradient id='low' x1='0%' y1='0%' x2='100%' y2='0%'>
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='100%' stopColor='#4167d9' />
                    </linearGradient>
                </defs>
            </svg>
            <div className='content__content reports__content'>
                {isResult && !loadingResult ? (
                    <div className='reports__content__chart reports__content__mobile'>
                        {
                            <div style={getPrimaryReportDivStyle()}>
                                {reportData ? (
                                    <div className='reports__content__item reports__content__item--all'>
                                        {reportType === 'planogram' && (
                                            <Fragment>
                                                {(reportFilter && (
                                                    <Fragment>
                                                        <div className='reports__controls__item reports__content__item reports__content__item--daterange'>
                                                            <div className='reports__controls__item__title reports__content__item__title--daterange reports__label'>
                                                                Select a timeframe
                                                            </div>
                                                            <div className='reports__controls__item__content reports__content__item__content--daterange'>
                                                                <DateRangePicker
                                                                    startDatePlaceholderText='Start'
                                                                    startDate={
                                                                        startDate
                                                                    }
                                                                    onDatesChange={
                                                                        handleOnDateChange
                                                                    }
                                                                    endDatePlaceholderText='End'
                                                                    endDate={
                                                                        endDate
                                                                    }
                                                                    isOutsideRange={() =>
                                                                        false
                                                                    }
                                                                    numberOfMonths={
                                                                        1
                                                                    }
                                                                    displayFormat='MMM D'
                                                                    showClearDates={
                                                                        true
                                                                    }
                                                                    focusedInput={
                                                                        secondDatepickerFocus
                                                                    }
                                                                    onFocusChange={(
                                                                        focus: any
                                                                    ) =>
                                                                        setSecondDatepickerFocusFocus(
                                                                            focus
                                                                        )
                                                                    }
                                                                    startDateId='startDateMookh'
                                                                    endDateId='endDateMookh'
                                                                    minimumNights={
                                                                        0
                                                                    }
                                                                    withPortal={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {(selectedPlanogramTypeOption.value ===
                                                            'product' && (
                                                            <React.Fragment>
                                                                <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                                    Compliance
                                                                    for{' '}
                                                                    {
                                                                        reportFilter
                                                                    }
                                                                </p>
                                                            </React.Fragment>
                                                        )) || (
                                                            <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                                Compliance for{' '}
                                                                {reportFilter}
                                                            </p>
                                                        )}
                                                    </Fragment>
                                                )) || (
                                                    <React.Fragment>
                                                        <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                            Compliance by{' '}
                                                            {selectedPlanogramTypeOption.value ===
                                                            'product'
                                                                ? 'Product'
                                                                : 'KPIs'}{' '}
                                                            Category
                                                        </p>
                                                        <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_period'>
                                                            Report Period:{' '}
                                                            {dateYear.start}
                                                            {' - '}
                                                            {dateYear.end}
                                                            {', '}
                                                            {dateYear.year}
                                                        </p>
                                                    </React.Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        {reportData?.overall ? (
                                            <div className='reports__overall'>
                                                <CircularProgressbar
                                                    value={reportData.overall}
                                                    strokeWidth={3}
                                                    counterClockwise
                                                    text={`${Math.round(
                                                        reportData.overall
                                                    )}%`}
                                                    styles={buildStyles({
                                                        trailColor: '#F4F8FF',
                                                        textColor: '#121E34',
                                                        pathColor: `url(${getSVGDef(
                                                            reportData.overall
                                                        )})`,
                                                    })}
                                                />
                                            </div>
                                        ) : null}
                                        {reportData?.category_wise_data ? (
                                            <div className='reports__category'>
                                                {Object.keys(
                                                    reportData.category_wise_data
                                                ).map((key) => {
                                                    return (
                                                        <div
                                                            className='reports__category__item'
                                                            key={key}
                                                        >
                                                            <a
                                                                className='reports__category__item__link'
                                                                onClick={() => {
                                                                    if (
                                                                        secondaryReportFilter ||
                                                                        reportType ===
                                                                        'promotion'
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    const filter: any = key;
                                                                    setSecondaryReportFilter(
                                                                        filter
                                                                    );
                                                                    history.push({
                                                                        pathname: '/non-compliance',
                                                                        state: {
                                                                            backLink: '/report/planogram',
                                                                            params: {
                                                                                compliance: key,
                                                                                percentage: reportData.category_wise_data[key]?.percentage,
                                                                                label: reportFilter
                                                                            },
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <div className='reports__category__item__content'>
                                                                    <div className='reports__category__item__title'>
                                                                        <div className='reports__category__item__title__key'>
                                                                            {key}
                                                                        </div>
                                                                        <div className='reports__category__item__title__result'>
                                                                            {Math.round(
                                                                                reportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                ]
                                                                                    ?.percentage
                                                                            )}
                                                                            %
                                                                        </div>
                                                                    </div>
                                                                    <div className='reports__category__item__bar'>
                                                                        <div
                                                                            className='reports__category__item__bar__content'
                                                                            style={getRowstyle(
                                                                                reportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className='reports__content__item--none'>
                                        No reports found
                                    </div>
                                )}
                            </div>
                        }

                        {reportType !== 'promotion' && (
                            <div style={getSecondaryReportDivStyle()}>
                                {secondaryReportData ? (
                                    <div className='reports__content__item reports__content__item--all'>
                                        <Fragment>
                                            {(secondaryReportFilter && (
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {secondaryReportFilter}{' '}
                                                    Compliance Results
                                                </p>
                                            )) || (
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    Results by Compliance
                                                    Category{' '}
                                                </p>
                                            )}
                                        </Fragment>
                                        {secondaryReportData?.overall ? (
                                            <div className='reports__overall'>
                                                <CircularProgressbar
                                                    value={
                                                        secondaryReportData.overall
                                                    }
                                                    strokeWidth={3}
                                                    counterClockwise
                                                    text={`${Math.round(
                                                        secondaryReportData.overall
                                                    )}%`}
                                                    styles={buildStyles({
                                                        trailColor: '#F4F8FF',
                                                        textColor: '#121E34',
                                                        pathColor: `url(${getSVGDef(
                                                            secondaryReportData.overall
                                                        )})`,
                                                    })}
                                                />
                                            </div>
                                        ) : null}
                                        {secondaryReportData?.category_wise_data ? (
                                            <div className='reports__category'>
                                                {Object.keys(
                                                    secondaryReportData.category_wise_data
                                                ).map((key) => {
                                                    return (
                                                        <div className='reports__category__item'>
                                                            <a
                                                                className='reports__category__item__link'
                                                                onClick={() => {
                                                                    history.push({
                                                                        pathname: '/report/planogram/non-compliance',
                                                                        state: {
                                                                            backLink: '/report/planogram',
                                                                            params: {
                                                                                compliance: key,
                                                                                percentage: secondaryReportData.category_wise_data[key]?.percentage,
                                                                                label: reportFilter
                                                                            },
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <div className='reports__category__item__content'>
                                                                    <div className='reports__category__item__title'>
                                                                        <div className='reports__category__item__title__key'>
                                                                            {
                                                                                key
                                                                            }
                                                                        </div>
                                                                        <div className='reports__category__item__title__result'>
                                                                            {Math.round(
                                                                                secondaryReportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                ]
                                                                                    ?.percentage
                                                                            )}
                                                                            %
                                                                        </div>
                                                                    </div>
                                                                    <div className='reports__category__item__bar'>
                                                                        <div
                                                                            className='reports__category__item__bar__content'
                                                                            style={getRowstyle(
                                                                                secondaryReportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {reportFilter ||
                                                                reportType ===
                                                                    'promotion' ? null : (
                                                                    <div className='reports__category__item__icon'>
                                                                        <img
                                                                            src={
                                                                                rightIcon
                                                                            }
                                                                            alt=''
                                                                        />
                                                                    </div>
                                                                )}
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className='reports__content__item--none'>
                                        No reports found
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : isResult && loadingResult ? (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default ReportFilter;

import React from 'react';
import { useHistory } from 'react-router';

import { useAuth0 } from '../../lib/auth';
import { namespace } from '../../lib/getuserdetails';
import { Button } from '../../components/button/button-container';
import secureStorage from '../../lib/localStorage';
import constants from '../../lib/constants';

import logo from "../../images/logo-full.png";
import './login.scss';

const Login: React.FC = () => {
    const history = useHistory();
    const {
        loginWithRedirect,
        isAuthenticated,
        getIdTokenClaims
    } = useAuth0();

    const onLoginClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        await loginWithRedirect()
    };
    // TODO: Cannot move it to util since auth0 is a react hook
    const getUserDetail = async () => {
        const claims: any = await getIdTokenClaims();
        const claimsData = claims ?.[`${namespace}claims/`];
        const idToken = claims.__raw;
        const userRoles = claimsData ?.roles ?? [];
        let userFinalRole = undefined;
        for (let role of ['associate', 'manager', 'headquarter']) {
            if (userRoles.includes(role)) {
                userFinalRole = role;
            }
        }

        secureStorage.setItem(constants.ID_TOKEN, idToken);
        secureStorage.setItem(constants.USER_ROLE, userFinalRole);
        secureStorage.setItem(constants.CLAIMS, claimsData);

        return [claims, userFinalRole];
    };

    const onAuth = async () => {
        const userFinalRoleData = await getUserDetail();
        console.log(userFinalRoleData);
        // an user role exists
        if (!(userFinalRoleData[0])) {
            return;
        }
        const userFinalRole = userFinalRoleData[1];
        if (userFinalRole) {
            // redirect to home
            history.push("/home");
            return;
        }
        // Since user role doesn't exist, first get user details
        history.push("/registration");
    };

    if (isAuthenticated) {
        // TODO: redirect to the url the user got auth-walled instead of home all the time
        onAuth();
    }

    return (
        <div className="login">
            <div className="login__display">
                <div className="login__logo">
                    <img className="login__logo__image" src={logo} alt="" />
                </div>
            </div>
            <div className="login__action">
                <div className="login__login">
                    <Button
                        className="btn btn--primary btn--full-width"
                        onClick={onLoginClick}
                        buttonText="Login"
                    />
                </div>
                <div className="login__register">
                    Don't have account? <a href="" className="login__register__link" onClick={onLoginClick}>Create it!</a>
                </div>
            </div>
        </div>
    )
};

export default Login;
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

import './task.scss';

import defaultAvatar from '../../images/default-avatar-v2.svg';
import completed from '../../images/completed.png';
import completedDisabled from '../../images/completed-disabled.png';

import { useAuth0 } from '../../lib/auth';

import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    Button,
    LoadingButton,
} from '../../components/button/button-container';
import TaskActivities from '../../components/taskactivities/taskactivities-container';
import cogoToast from 'cogo-toast';
import { namespace, REACT_APP_API_ENDPOINT } from '../../lib/getuserdetails';
import {
    getTaskDetails,
    getStoreDetails,
    markTaskAsDone,
    updateTask,
    fetchPutApi,
    fetchGetApi,
    convertDate,
    convertDateToString,
    addComment,
    convertToFromNow,
    getUser,
    fetchDelApi,
} from '../../lib/apiWrappers';
import cancel from '../../images/cancel.png';

const customAddActivityModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        // height: '60vh', // <-- This sets the height
        overflow: 'scroll',
    },
};

const customDeleteModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        // height: '60vh', // <-- This sets the height
        overflow: 'scroll',
    },
};

const customTaskDoneModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'none',
        // height: '60vh', // <-- This sets the height
    },
};

const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'grey' : 'white',
    }),
    control: (base: any, state: any) => ({
        ...base,
        'minHeight': '46px',
    }),
};

const Task: React.FC = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const [taskData, setTaskData] = useState<any>({});
    const [taskComments, setTaskComments] = useState<any[]>([]);
    const [userId, setUserId] = useState('');
    const [taskId, setTaskId] = useState('');
    const [taskCreatedBy, setTaskCreatedBy] = useState('');
    const [taskType, setTaskType] = useState('');
    const [storeName, setStoreName] = useState(undefined);
    const [markAsDoneLoading, setMarkAsDoneLoading] = useState(false);
    const [markAsNotDoneLoading, setMarkAsNotDoneLoading] = useState(false);
    const [requestReviewLoading, setRequestReviewLoading] = useState(false);
    const [reviewRequested, setReviewRequested] = useState(false);
    const [reviewCompleted, setReviewCompleted] = useState(false);
    const [taskisDone, setTaskIsDone] = useState(false);
    const [addActivityModalIsOpen, setAddActivityModalIsOpen] = useState(false);
    const [assignTaskModalIsOpen, setAssignTaskModalIsOpen] = useState(false);
    const [activityDescription, setActivityDescription] = useState('');
    const [taskComment, setTaskComment] = useState('');
    const [taskAssignedTo, setTaskAssignedTo] = useState<string[]>([]);
    const [addCommentsLoading, setAddCommentsLoading] = useState(false);
    const [activityFile, setActivityFile] = useState<FileList | null>(null);
    const [activityFileSrc, setActivityFileSrc] = useState('');
    const [activitySubmitting, setActivitySubmitting] = useState(false);
    const [userRole, setUserRole] = useState('associate');
    const [storeAssociates, setStoreAssociates] = useState([]);
    const [selectedAssociate, setSelectedAssociate] = useState([
        { value: '', label: 'No associates found' },
    ]);
    const [assignTaskLoading, setAssignTaskLoading] = useState(false);
    const [taskDoneModalIsOpen, setTaskDoneModalIsOpen] = useState(false);
    const [taskLoading, setTaskLoading] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { getIdTokenClaims } = useAuth0();
    const getTask = async () => {
        setTaskLoading(true);
        const params: any = match.params;
        const taskId = params.id ?? '';
        if (!taskId) {
            cogoToast.error('No tasks found');
            return null;
        }
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const claimsData = claims[`${namespace}claims/`];
        const uId = claimsData?.app_metadata?.uid;
        setUserId(uId);
        const taskDetails = await getTaskDetails(taskId, idToken);
        if (!taskDetails.success) {
            cogoToast.error('No tasks found');
            return null;
        }
        const userRoles = claimsData?.roles ?? [];
        let userFinalRole = 'associate';
        // TODO: get a better way to store the possible roles
        for (let role of ['associate', 'manager', 'headquarter']) {
            if (userRoles.includes(role)) {
                userFinalRole = role;
            }
        }
        setUserRole(userFinalRole);
        setTaskData(taskDetails.data);
        setTaskId(taskDetails.data?._id);
        setTaskCreatedBy(taskDetails.data?._created_by);
        const storeId = taskDetails.data?._store;
        const storeDetails = await getStoreDetails(storeId, idToken);
        setStoreName(storeDetails.name);
        setReviewRequested(taskDetails.data?.review_requested);
        setReviewCompleted(taskDetails.data?.review_completed);
        setTaskComments(taskDetails.data.comments);
        setTaskType(taskDetails.data?.type);
        const taskStatus = taskDetails.data?.status;
        const taskAssignedTo: string[] = taskDetails.data?._assigned_to;
        if (taskAssignedTo) {
            let assignedUsersDetails: string[] = [];
            for (let taskId of taskAssignedTo) {
                const userDetails = await getUser(taskId, idToken);
                assignedUsersDetails.push(userDetails?.data?.full_name);
            }
            setTaskAssignedTo(assignedUsersDetails);
            // const assignedUsersDetails: string[] = taskAssignedTo.map(taskId => {

            // })
        }
        if (taskStatus === 'cv_pending' || taskStatus === 'completed') {
            setTaskIsDone(true);
        }
        // if (userRole !== 'associate') {
        const getStoreAssociatesEndPoint = `${REACT_APP_API_ENDPOINT}/users/?pendingApproval=false&role=associate`;
        const responseJsonStoreAssociatesJson = await fetchGetApi(
            getStoreAssociatesEndPoint,
            idToken
        );
        const fetchedAssociates: any = [];
        if (responseJsonStoreAssociatesJson.success) {
            for (let associate of responseJsonStoreAssociatesJson.data) {
                fetchedAssociates.push({
                    label: associate?.full_name,
                    value: associate?._id,
                });
            }
            setStoreAssociates(fetchedAssociates);
            const taskDetail = taskDetails.data;
            if (fetchedAssociates.length > 0 &&
                taskDetail &&
                taskDetail._assigned_to &&
                taskDetail._assigned_to.length > 0) {
                const assignee = fetchedAssociates.find((elem: { label: string; value: string }) => elem.value === taskDetail._assigned_to[0]);
                if (assignee) {
                    setSelectedAssociate([assignee]);
                } else {
                    setSelectedAssociate([fetchedAssociates[0]]);
                }
            }
        }
        // }
        setTaskLoading(false);
    };
    const handleClickTaskDelete = async () => {
        setDeleteLoading(true);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const taskDeleteEndPoint = `${REACT_APP_API_ENDPOINT}/task/${taskId}`;
        const responseTaskDelete = await fetchDelApi(
            taskDeleteEndPoint,
            idToken
        );

        if (responseTaskDelete.success) {
            setDeleteLoading(false);
            // cogoToast.success('Task successfully deleted');
            if (taskType === 'audit') {
                // history.push('/audittasks');
                history.push({
                    pathname: '/deleted',
                    state: {
                        title: 'All Audit Task',
                        backLink: '/audittasks',
                    },
                });
            } else if (taskType === 'manual') {
                // history.push('/customtasks');
                history.push({
                    pathname: '/deleted',
                    state: {
                        title: 'All Custom Task',
                        backLink: '/customtasks',
                    },
                });
            } else if (taskType === 'promotion') {
                history.push('/promotiontasks');
                history.push({
                    pathname: '/deleted',
                    state: {
                        title: 'All Promotion Task',
                        backLink: '/promotiontasks',
                    },
                });
            } else {
                history.push('/home');
            }
            return;
        }
        setDeleteLoading(false);
        cogoToast.error(responseTaskDelete.data);
        return;
    };
    const handleClickMarkAsNotDone = async () => {
        setMarkAsNotDoneLoading(true);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        let newStatus = 'draft';
        if (taskAssignedTo) {
            newStatus = 'assigned';
        }
        const markTaskAsNotDoneResponse = await updateTask(taskId, idToken, {
            title: taskData?.title,
            description: taskData?.description,
            status: newStatus,
            redo_requested: true,
        });
        if (markTaskAsNotDoneResponse.success) {
            setTaskIsDone(false);
            cogoToast.success('Task is marked as not done');
            setMarkAsNotDoneLoading(false);
            return;
        }
        cogoToast.error('Task could not be marked as not done');
        setMarkAsNotDoneLoading(false);
        return;
    };
    const handleClickMarkAsDone = async () => {
        setMarkAsDoneLoading(true);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const markTaskAsDoneResponse = await markTaskAsDone(
            taskId,
            idToken,
            userId
        );
        if (markTaskAsDoneResponse.success) {
            // cogoToast.success('Task is completed');
            setTaskIsDone(true);
            setTaskDoneModalIsOpen(false);
            if (taskType === 'audit') {
                // history.push('/audittasks');
                history.push({
                    pathname: '/completed',
                    state: {
                        title: 'All Audit Task',
                        backLink: '/audittasks',
                    },
                });
            } else if (taskType === 'manual') {
                // history.push('/customtasks');
                history.push({
                    pathname: '/completed',
                    state: {
                        title: 'All Custom Task',
                        backLink: '/customtasks',
                    },
                });
            } else if (taskType === 'promotion') {
                history.push('/promotiontasks');
                history.push({
                    pathname: '/completed',
                    state: {
                        title: 'All Promotion Task',
                        backLink: '/promotiontasks',
                    },
                });
            } else {
                history.push('/home');
            }
            return;
        } else {
            cogoToast.error('Task could not be completed');
        }
        setMarkAsDoneLoading(false);
    };

    const handleClickAddFile = async (selectedFile: FileList | null) => {
        if (selectedFile) {
            setActivityFile(selectedFile);
        }
    };
    useEffect(() => {
        if (!activityFile) {
            return;
        }
        if (activityFile[0]) {
            setActivityFileSrc(URL.createObjectURL(activityFile[0]));
        }
    }, [activityFile]);
    const handleClickSubmitActivity = async () => {
        setActivitySubmitting(true);
        // TODO: Very repetitive
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const formData = new FormData();
        if (activityFile) {
            formData.append('image', activityFile[0]);
        }
        formData.append('description', activityDescription);
        const submitActivityofTaskEndPoint = `${REACT_APP_API_ENDPOINT}/task/${taskId}/work-detail`;
        const submitActivityofTaskEndPointResponse = await fetch(
            submitActivityofTaskEndPoint,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
                body: formData,
            }
        );
        const submitActivityofTaskEndPointResponseJson = await submitActivityofTaskEndPointResponse.json();
        if (!submitActivityofTaskEndPointResponseJson.success) {
            cogoToast.error(submitActivityofTaskEndPointResponseJson.data);
        } else {
            // cogoToast.success("Activity added successfully");
            if (taskType === 'audit') {
                const markTaskAsDoneResponse = await markTaskAsDone(
                    taskId,
                    idToken,
                    userId
                );
                if (markTaskAsDoneResponse.success) {
                    // cogoToast.success('Task is completed');
                    setTaskIsDone(true);
                    history.push({
                        pathname: '/completed',
                        state: {
                            title: 'All Audit Store Task',
                            backLink: '/audittasks',
                        },
                    });
                    return;
                } else {
                    cogoToast.error('Task could not be completed');
                }
            }
            setActivityFileSrc('');
            setAddActivityModalIsOpen(false);
        }
        setActivitySubmitting(false);
    };
    useEffect(() => {
        getTask();
    }, [
        activitySubmitting,
        addCommentsLoading,
        assignTaskModalIsOpen,
        taskisDone,
        reviewCompleted,
        reviewRequested,
    ]);
    useEffect(() => {
        getTask();
    }, []);
    const handleClickAddComment = async () => {
        setAddCommentsLoading(true);
        if (!taskComment) {
            cogoToast.error('Comments can not be empty');
            setAddCommentsLoading(false);
            return;
        }
        if (!taskId) {
            return;
        }
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const addCommentResponseJson = await addComment(
            taskId,
            taskComment,
            idToken
        );
        if (addCommentResponseJson.success) {
            cogoToast.success('Comment added successfully');
            setTaskComment('');
        } else {
            cogoToast.error('Comment could not be added');
        }
        setAddCommentsLoading(false);
    };
    const handleClickAssignTask = async (assignee: { value: string; label: string }) => {
        setAssignTaskLoading(true);
        if (!assignee || !assignee.value) {
            cogoToast.error('Task could not be assigned');
            return;
        }
        setSelectedAssociate([assignee]);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const markAssignTaskPoint = `${REACT_APP_API_ENDPOINT}/task/${taskId}/assign`;
        const user_ids = [assignee.value];
        const responseAssignTaskJson = await fetchPutApi(
            markAssignTaskPoint,
            idToken,
            {
                user_ids: user_ids,
            }
        );
        if (responseAssignTaskJson.success) {
            cogoToast.success('Task has been assigned');
            setAssignTaskLoading(false);
            setAssignTaskModalIsOpen(false);
            return;
        }
        cogoToast.error('Task could not be assigned');
        setAssignTaskLoading(true);
        return;
    };
    const handleClickCancelReview = async () => {
        setRequestReviewLoading(true);
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        if (!taskId) {
            return;
        }
        const updateTaskReviewRequestedEndpoint = `${REACT_APP_API_ENDPOINT}/task/${taskId}/request-review`;
        const reviewRequestedUpdateResponse = await fetchPutApi(
            updateTaskReviewRequestedEndpoint,
            idToken,
            {
                unsetReviewRequest: true,
            }
        );
        if (reviewRequestedUpdateResponse.success) {
            cogoToast.success('Request for review is cancelled');
            setReviewRequested(false);
            setRequestReviewLoading(false);
        } else {
            cogoToast.error(reviewRequestedUpdateResponse.data);
            setRequestReviewLoading(false);
        }
        return;
    };
    // if (taskLoading) {
    //     return (
    //         <div className="content content--task task task--loading">
    //             <div className="loadingSpinner"></div>
    //         </div>
    //     )
    // }
    return (
        <div className='content content--task task'>
            <div className={`task__loading-overlay--${taskLoading}`}>
                <div className='loadingSpinner' />
            </div>
            <div className='content__content task__content'>
                <div className='content__item task__item task__item--title'>
                    <div className='task__item--content'>{taskData?.title}</div>
                </div>
                {taskData?.description ? (
                    <div className='content__item task__item task__item--description'>
                        <div className='task__item--description__content'>
                            {taskData?.description}
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
                {taskData?.created_at && taskType !== 'audit' ? (
                    <div className='content__item task__item task__item--createdAt'>
                        <div className='content__item task__item--createdAt__title'>
                            Created at
                        </div>
                        <div className='content__item task__item--createdAt__content'>
                            <div className='task__item--content'>
                                {convertDate(taskData?.created_at)}
                            </div>
                        </div>
                    </div>
                ) : null}
                {taskData?.due_date ? (
                    <div className='content__item task__item task__item--due-date'>
                        <div className='content__item task__item--due-date__title'>
                            Due by
                        </div>
                        <div className='content__item task__item--due-date__content'>
                            <div className='task__item--content'>
                                {convertDate(taskData?.due_date)}
                            </div>
                        </div>
                    </div>
                ) : null}
                {/* {taskData?.promotion_metadata ? (
                    <div className='content__item task__item task__item--due-date'>
                        <div className='content__item task__item--due-date__title'>
                            Start date
                        </div>
                        <div className='content__item task__item--due-date__content'>
                            {convertDate(
                                taskData?.promotion_metadata?.start_date
                            )}
                        </div>
                    </div>
                ) : null}
                {taskData?.promotion_metadata ? (
                    <div className='content__item task__item task__item--due-date'>
                        <div className='content__item task__item--due-date__title'>
                            End date
                        </div>
                        <div className='content__item task__item--due-date__content'>
                            {convertDate(
                                taskData?.promotion_metadata?.end_date
                            )}
                        </div>
                    </div>
                ) : null} */}

                {storeName && taskType !== 'audit' && userRole !== 'manager' ? (
                    <div className='content__item task__item task__item--store'>
                        {/* <span className='task__item--store__content'>
                            {storeName}
                        </span> */}
                        <div className='content__item task__item--store__title'>
                            Applicable Location
                        </div>
                        <div className='content__item task__item--store__content'>
                            <div className='task__item--content'>
                                {storeName}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}
                <div className='content__item task__item task__review-section'>
                    <div className='task__review-tags'>
                        {reviewCompleted ? (
                            <div className='tasks__item__tag tasks__item__tag--review-completed'>
                                Review completed
                            </div>
                        ) : null}
                        {taskType ? (
                            <div
                                className={`tasks__item__tag tasks__item__tag--${taskType}`}
                            >
                                {taskType}
                            </div>
                        ) : null}
                    </div>
                    {userRole === 'manager' && taskType !== 'audit' ? (
                        <div className='content__item task__item--assignTask'>
                            <div className='task__item--assignTask__label task__item--store__title'>
                                Assigned to
                            </div>
                            <div className='task__item--assignTask__input'>
                                {/* <div className='task__item--assignTask__name'>
                                    {taskAssignedTo !== []
                                        ? taskAssignedTo.join(', ')
                                        : 'None'}
                                </div> */}
                                <Select
                                    // isMulti
                                    value={selectedAssociate}
                                    onChange={(selectedOption: any) => handleClickAssignTask(selectedOption)}
                                    options={storeAssociates}
                                    className='task__item--assignTask__dropdown'
                                    styles={customStyles}
                                />
                                {assignTaskLoading && (
                                    <div className={`task__loading-overlay--${assignTaskLoading}`}>
                                        <div className='loadingSpinner' />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
                {taskData?.promotion_metadata ? (
                    <div className='content__item task__item task__item--store'>
                        {/* <span className='task__item--store__content'>
                            {storeName}
                        </span> */}
                        <div className='content__item task__item--store__title'>
                            Promotion Duration
                        </div>
                        <div className='content__item task__item--store__content'>
                            <div className='task__item--content'>
                                {convertDateToString(
                                    taskData?.promotion_metadata?.start_date
                                )}{' '}
                                -{' '}
                                {convertDateToString(
                                    taskData?.promotion_metadata?.end_date
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
                <div className='content__item task__item task__item--image'>
                    <div className='task__item--image__title'>Images</div>
                    {taskData?.image_url ? (
                        <img
                            alt=''
                            className='task__item--image__content'
                            src={taskData?.image_url}
                        />
                    ) : (
                        <div className='task__item--image__empty'>
                            No Images found
                        </div>
                    )}
                </div>
                {taskData?.taskWorks?.length > 0 && (
                    <div className='content__item task__item task__item--activity activity'>
                        <div className='activity__title'>Images</div>
                        {taskData?.taskWorks?.length > 0 ? (
                            <div className='activity__content'>
                                <TaskActivities activities={taskData.taskWorks} />
                            </div>
                        ) : (
                            <div className='activity__content activity__content--empty'>
                                No Images found
                            </div>
                        )}
                    </div>
                )}
                {userRole !== 'headquarter' ? (
                    <div className='content__item task__item task__item--comments comments'>
                        {/* <div className='activity__title'>Comments</div> */}
                        <div className='activity__content'>
                            {
                                // TODO: Should be made separate component later
                                taskData?.comments?.length > 0 ? (
                                    <div className='activity__content'>
                                        {taskComments.map((comment, index) => {
                                            return (
                                                <div className='comment' key={String(index)}>
                                                    <div className='comment__header'>
                                                        <div className='comment__header__left'>
                                                            <div className='comment__pic'>
                                                                {comment?._user
                                                                    ?.image ? null : (
                                                                    // TODO: When user picture is added, use this
                                                                    <img
                                                                        src={
                                                                            defaultAvatar
                                                                        }
                                                                        alt=''
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='comment__header__right'>
                                                            <div className='comment__header__right__top'>
                                                                <div className='comment__name'>
                                                                    {
                                                                        comment
                                                                            ?._user
                                                                            ?.full_name
                                                                    }
                                                                </div>
                                                                <div className='comment__role'>
                                                                    {
                                                                        comment
                                                                            ?._user
                                                                            ?.role
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='comment__header__right__bottom'>
                                                                <div className='comment__time'>
                                                                    {convertToFromNow(
                                                                        comment?.updated_at
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='comment__body'>
                                                                <pre className='comment__body__content'>
                                                                    {
                                                                        comment?.description
                                                                    }
                                                                </pre>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : // <div className='activity__content activity__content--empty'>
                                //     No comments found
                                // </div>
                                null
                            }
                        </div>
                        {/* {taskType !== 'audit' ? ( */}
                        <div className='comments__add'>
                            <label className='pure-material-textfield-outlined comments__add--comment'>
                                <textarea
                                    placeholder='Comment'
                                    value={taskComment}
                                    onChange={(e) =>
                                        setTaskComment(e.target.value)
                                    }
                                    onBlur={(e) =>
                                        setTaskComment(e.target.value)
                                    }
                                    rows={6}
                                />
                                {/* <span>Your comment</span> */}
                            </label>
                            {addCommentsLoading ? (
                                <LoadingButton
                                    className='btn btn--primary'
                                    onClick={() => {
                                        return null;
                                    }}
                                />
                            ) : (
                                <Button
                                    className='btn btn--primary comments__add--button'
                                    onClick={handleClickAddComment}
                                    buttonText='Add comments'
                                />
                            )}
                        </div>
                        {/* ) : null} */}
                    </div>
                ) : null}
                {userRole === 'manager' && userId === taskCreatedBy ? (
                    <div className='content__item task__item task__item--delete'>
                        <Button
                            className='btn btn--link-danger'
                            onClick={() => setDeleteModalIsOpen(true)}
                            buttonText='Delete'
                        />
                    </div>
                ) : null}
            </div>
            <div className='content__cta task__cta'>
                {(taskType === 'audit' && taskisDone) ||
                userRole === 'headquarter' ? null : (
                    <div
                        className={`task__cta__item task__cta__item--${userRole} task__cta__item--${taskType}`}
                    >
                        <button
                            className='task__cta__button task__cta__button--add-activity btn btn--primary'
                            onClick={() => {
                                setAddActivityModalIsOpen(true);
                            }}
                        >
                            {taskType === 'audit' && userRole === 'associate'
                                ? 'Capture photo'
                                : 'Capture photo'}
                        </button>
                    </div>
                )}
                {
                    // remove the proceed button for audit type of tasks
                    userRole !== 'headquarter' && taskType !== 'audit' ? (
                        // Don't show this button to headquarter at all
                        <div className='task__cta__item'>
                            {
                                // Review is requested for the task already
                                reviewRequested ? (
                                    // An associate can not do anything till the review is completed
                                    userRole === 'associate' ? (
                                        requestReviewLoading ? (
                                            <button
                                                className='task__cta__button task__cta__button--highlight btn'
                                                onClick={() => {
                                                    return null;
                                                }}
                                            >
                                                <div className='loadingSpinner'></div>
                                            </button>
                                        ) : (
                                            <button
                                                className='task__cta__button task__cta__button--highlight btn'
                                                onClick={
                                                    handleClickCancelReview
                                                }
                                            >
                                                Cancel review
                                            </button>
                                        )
                                    ) : (
                                        // A manager can mark reviewed or completed
                                        <button
                                            className={`task__cta__button task__cta__button--highlight${
                                                taskisDone ? '-dark' : ''
                                            } btn`}
                                            onClick={() =>
                                                setTaskDoneModalIsOpen(true)
                                            }
                                        >
                                            Proceed
                                        </button>
                                    )
                                ) : // review is not requested
                                taskisDone ? (
                                    // the task is already done
                                    markAsNotDoneLoading ? (
                                        <button
                                            className='task__cta__button task__cta__button--highlight-dark btn'
                                            onClick={() => {
                                                return null;
                                            }}
                                        >
                                            <div className='loadingSpinner'></div>
                                        </button>
                                    ) : (
                                        <button
                                            className='task__cta__button task__cta__button--highlight-dark btn'
                                            onClick={handleClickMarkAsNotDone}
                                        >
                                            Mark as not done
                                        </button>
                                    )
                                ) : (
                                    // review is not requested and task is not done
                                    <button
                                        className={`task__cta__button task__cta__button--highlight${
                                            taskisDone ? '-dark' : ''
                                        } btn`}
                                        onClick={() =>
                                            setTaskDoneModalIsOpen(true)
                                        }
                                    >
                                        Submit
                                    </button>
                                )
                            }
                        </div>
                    ) : null
                }
            </div>
            <Modal
                isOpen={addActivityModalIsOpen}
                onRequestClose={() => {
                    setActivityFileSrc('');
                    setAddActivityModalIsOpen(false)
                }}
                style={customAddActivityModalStyles}
                contentLabel='Add photo'
                ariaHideApp={false}
            >
                <div className='addactivitymodal'>
                    <div className='addactivitymodal__header'>
                        <div className='addactivitymodal__title'>
                            {taskType === 'audit' && userRole === 'associate'
                                ? 'Capture photo'
                                : 'Capture photo'}
                        </div>
                        <div
                            className='addactivitymodal__close'
                            onClick={() => {
                                setActivityFileSrc('');
                                setAddActivityModalIsOpen(false);
                            }}
                        >
                            <img src={cancel} alt='' />
                        </div>
                    </div>
                    <div className='addactivitymodal__content'>
                        <div className='addactivitymodal__content__item addactivitymodal__file'>
                            <div className='addactivitymodal__file__preview'>
                                <img src={activityFileSrc} alt='' style={{maxHeight: '240px', width: 'auto', display: 'block', margin: '0 auto'}} />
                            </div>
                            <label className='btn btn--primary-yellow btn--full-width addactivitymodal__file__button'>
                                <input
                                    type='file'
                                    accept='image/x-png,image/jpeg'
                                    id='activity-file-input'
                                    onChange={(e) =>
                                        handleClickAddFile(e.target.files)
                                    }
                                />
                                {activityFileSrc === ''
                                    ? 'Add an image'
                                    : 'Replace the image'}
                            </label>
                        </div>
                        <div className='addactivitymodal__content__item'>
                            <label className='pure-material-textfield-outlined'>
                                <textarea
                                    placeholder=' '
                                    value={activityDescription}
                                    onChange={(e) =>
                                        setActivityDescription(e.target.value)
                                    }
                                    onBlur={(e) =>
                                        setActivityDescription(e.target.value)
                                    }
                                />
                                <span>Description</span>
                            </label>
                        </div>
                        <div className='addactivitymodal__cta'>
                            {activitySubmitting ? (
                                <button
                                    className='addactivitymodal__cta__button btn btn--full-width'
                                    onClick={() => {
                                        return null;
                                    }}
                                >
                                    <div className='loadingSpinner' />
                                </button>
                            ) : (
                                <button
                                    className='addactivitymodal__cta__button btn btn--full-width'
                                    onClick={handleClickSubmitActivity}
                                >
                                    Submit
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={assignTaskModalIsOpen}
                onRequestClose={() => setAssignTaskModalIsOpen(false)}
                style={customAddActivityModalStyles}
                contentLabel='Assign task'
                ariaHideApp={false}
            >
                <div className='addactivitymodal'>
                    <div className='addactivitymodal__header'>
                        <div className='addactivitymodal__title'>
                            Assign task
                        </div>
                        <div
                            className='addactivitymodal__close'
                            onClick={() => setAssignTaskModalIsOpen(false)}
                        >
                            <img src={cancel} alt='' />
                        </div>
                    </div>
                    <div className='addactivitymodal__content'>
                        {userRole === 'manager' ? (
                            <div className='content__item task__item--taskassign'>
                                <div className='task__item--taskassign__select'>
                                    <Select
                                        // isMulti
                                        value={selectedAssociate}
                                        onChange={(selectedOption: any) => {
                                            setSelectedAssociate([
                                                selectedOption,
                                            ]);
                                        }}
                                        options={storeAssociates}
                                    />
                                </div>
                                <div className='task__item--taskassign__cta'>
                                    {assignTaskLoading ? (
                                        <LoadingButton
                                            className='btn btn--primary btn--full-width'
                                            onClick={() => {
                                                return null;
                                            }}
                                        />
                                    ) : (
                                        <Button
                                            className='btn btn--primary btn--full-width'
                                            onClick={() => handleClickAssignTask(selectedAssociate[0])}
                                            buttonText='Assign task'
                                        />
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
            <Modal
                isOpen={taskDoneModalIsOpen}
                onRequestClose={() => setTaskDoneModalIsOpen(false)}
                style={customTaskDoneModalStyles}
                contentLabel='Proceed with task'
                overlayClassName='taskdonemodal__overlay'
                ariaHideApp={false}
                // className="taskdonemodal__wrapper"
            >
                <div className='taskdonemodal'>
                    <div
                        className='taskdonemodal__header'
                        onClick={() => setTaskDoneModalIsOpen(false)}
                    >
                        <img
                            className='taskdonemodal__header__icon'
                            src={cancel}
                            alt={''}
                        />
                    </div>
                    {/* <div className="taskdonemodal__item taskdonemodal__item--mark-completed"> */}
                    {reviewRequested ? (
                        <button
                            className='taskdonemodal__item taskdonemodal__item--mark-completed btn btn--full-width'
                            onClick={() => {
                                return null;
                            }}
                        >
                            <div className='taskdonemodal__item__icon'>
                                <img src={completedDisabled} alt='' />
                            </div>
                            <div className='taskdonemodal__item__content taskdonemodal__item__content--disabled'>
                                Mark as completed
                            </div>
                        </button>
                    ) : markAsDoneLoading ? (
                        <button
                            className='taskdonemodal__item taskdonemodal__item--mark-completed btn btn--full-width'
                            onClick={() => {
                                return null;
                            }}
                        >
                            <div className='loadingSpinner'></div>
                        </button>
                    ) : (
                        <button
                            className='taskdonemodal__item taskdonemodal__item--mark-completed btn btn--full-width'
                            onClick={handleClickMarkAsDone}
                        >
                            <div className='taskdonemodal__item__icon'>
                                <img src={completed} alt='' />
                            </div>
                            <div className='taskdonemodal__item__content'>
                                Mark as completed
                            </div>
                        </button>
                    )}

                    {/* </div> */}
                </div>
            </Modal>
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={() => setDeleteModalIsOpen(false)}
                style={customDeleteModalStyles}
                contentLabel='Confirm'
                ariaHideApp={false}
            >
                <div className='deletemodal'>
                    <div className='deletemodal__header'>
                        <div className='addactivitymodal__title'>Confirm</div>
                        <div
                            className='deletemodal__close'
                            onClick={() => setDeleteModalIsOpen(false)}
                        >
                            <img src={cancel} alt='' />
                        </div>
                    </div>
                    <div className='deletemodal__content'>
                        Are you sure to delete the task?
                    </div>
                    <div className='deletemodal__cta'>
                        {deleteLoading ? (
                            <button
                                className='deletemodal__cta__button btn btn--danger'
                                onClick={() => {
                                    return null;
                                }}
                            >
                                <div className='loadingSpinner'></div>
                            </button>
                        ) : (
                            <button
                                className='deletemodal__cta__button btn btn--danger'
                                onClick={handleClickTaskDelete}
                            >
                                Yes
                            </button>
                        )}
                        <button
                            className='deletemodal__cta__button btn'
                            onClick={() => setDeleteModalIsOpen(false)}
                        >
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Task;

import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import moment, { Moment } from 'moment';
import { extendMoment } from 'moment-range';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-daterange-picker/dist/css/react-calendar.css';

import rightIcon from '../../images/right.png';
import CalendarPicker from 'react-daterange-picker';
import constants from '../../lib/constants';
import secureStorage from "../../lib/localStorage";

import { getReport, getStoresByClient, getClustersByClient } from '../../lib/apiWrappers';

import './report.scss';

// @ts-ignore
const momentExtension = extendMoment(moment);

const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'grey' : 'white',
    }),
    control: (base: any) => ({
        ...base,
        'minHeight': '46px',
        margin: '0.5em 0',
    }),
};

const Report: React.FC = () => {
    const match = useRouteMatch();
    const history = useHistory();

    const [dateRange, setDateRange] = useState<{
        startDate: null | Moment,
        endDate: null | Moment,
    }>({
        startDate: null,
        endDate: null
    })
    const { startDate, endDate } = dateRange;
    const [dateYear, setDateYear] = useState({
        start: '',
        end: '',
        year: '',
    });
    const [focus, setFocus] = useState(null);
    const [isResult, setIsResult] = useState(false);
    const [loadingResult, setLoadingResult] = useState(true);
    const [secondDatepickerFocus, setSecondDatepickerFocusFocus] = useState(
        null
    );

    const [reportType, setReportType] = useState(undefined);
    const [reportFilter, setReportFilter] = useState(undefined);
    const [secondaryReportFilter, setSecondaryReportFilter] = useState(
        undefined
    );
    const [reportData, setReportData] = useState<any>(null);
    const [secondaryReportData, setSecondaryReportData] = useState<any>(null);
    const [viewByStore, setViewByStore] = useState(true);
    const [userRole, setUserRole] = useState<string | undefined>('associate');
    const [idToken, setIdToken] = useState<string | null>(null);
    const [viewByOptionSelected, setViewByOptionSelected] = useState<{
        value: string;
        label: string;
    }>({ value: '', label: 'Loading options...' });
    const [viewByOptions, setViewByOptions] = useState<
        { value: string; label: string }[]
        >([]);
    const [dateValue, setDateValue] = useState(
        momentExtension.range(
            momentExtension().clone(),
            momentExtension().clone()
        )
    );
    const selectCalendarValue = (value: any) => {
        setDateValue(value);
        setDateRange({ startDate: value.start, endDate: value.end });
    };

    const [viewTypeOptionSelected, setViewTypeOptionSelected] = useState<{
        value: string;
        label: string;
    }>({ value: 'view_by_store', label: 'View By Store' });
    const [
        selectedPlanogramTypeOption,
        setSelectedPlanogramTypeOption,
    ] = useState<{ value: string; label: string }>({
        value: 'product',
        label: 'Report By Product Category',
    });
    const handleChangePlanogramType = (selectedOption: {
        value: string;
        label: string;
    }) => {
        setSelectedPlanogramTypeOption(selectedOption);
        setReportFilter(undefined);
    };
    const handleClickChangeViewBy = (selectedOption: {
        value: string;
        label: string;
    }) => {
        if (selectedOption.value === 'view_by_store') {
            setViewByStore(true);
        } else {
            setViewByStore(false);
        }
        setViewTypeOptionSelected(selectedOption);
    };
    const getUserDetails = () => {
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);
        if (!userToken || !userRole) {
            return;
        }

        setUserRole(userRole);
        setIdToken(userToken);
    };

    const initiateReportDetails = async () => {
        if (userRole === 'associate' || !idToken) {
            return;
        }
        // get params
        const params: any = match.params;
        if (!params.type) {
            cogoToast.error('Please select a report type');
            history.push('/reportselectpre');
            return;
        }
        setReportType(params.type);
        setReportFilter(params.filter);
        // set store details

        const claimsData = secureStorage.getItem(constants.CLAIMS);
        const clientId = claimsData ?.app_metadata ?._client ?? undefined;

        let userStoresOptions: any = [];
        const stores = await getStoresByClient(clientId, idToken);
        stores.forEach(store => {
            if (store) {
                userStoresOptions.push({
                    value: store._id,
                    label: store.name
                });
            }
        });
        if (userStoresOptions.length === 0) {
            cogoToast.error(
                'No stores could be found associated with you. Please contact support.'
            );
            history.push('/home');
            return;
        }
    };
    const loadOptions = async () => {
        if (!idToken) {
            return;
        }
        const claimsData = secureStorage.getItem(constants.CLAIMS);
        const clientId = claimsData ?.app_metadata ?._client ?? undefined;
        if (!clientId) {
            cogoToast.error("You are not associated with any company, please contact support");
            history.push("/home");
            return;
        }
        let viewOptions: any = [];
        if (viewByStore) {
            const stores = await getStoresByClient(clientId, idToken);
            stores.forEach(store => {
                if (store) {
                    viewOptions.push({
                        value: store._id,
                        label: store.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No stores could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        } else {
            const clusters = await getClustersByClient(clientId, idToken);
            clusters.forEach(cluster => {
                if (cluster) {
                    viewOptions.push({
                        value: cluster._id,
                        label: cluster.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No Cluster could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        }
        setViewByOptions(viewOptions);
        setViewByOptionSelected(viewOptions[0]);
    };

    useEffect(() => {
        getUserDetails()
    }, []);

    useEffect(() => {
        initiateReportDetails();
    }, [viewByOptionSelected]);
    useEffect(() => {
        loadOptions();
    }, [viewByStore]);
    useEffect(() => {
        if (userRole === 'associate') {
            return;
        }
        loadOptions();
    }, [userRole]);
    const handleOnDateChange = ({ startDate, endDate }: any) =>
        setDateRange({ startDate, endDate });
    const getCurrentReport = async (
        storeOrClusterId: string,
        viewByStore: boolean,
        reportType: string | undefined,
        reportFilter: string | undefined,
        formatedStartDate: string,
        formatedEndDate: string,
        isSecondary?: Boolean | undefined
    ) => {
        if (!idToken) {
            return;
        }
        setLoadingResult(true);
        console.log({
            storeOrClusterId,
            viewByStore,
            reportType,
            reportFilter,
            formatedStartDate,
            formatedEndDate,
            idToken
        });
        const curentReportJson: any = await getReport(
            storeOrClusterId,
            viewByStore,
            reportType,
            reportFilter,
            formatedStartDate,
            formatedEndDate,
            idToken
        );
        if (!curentReportJson.success) {
            cogoToast.error('Report could not be retrieved');
            if (isSecondary) {
                setSecondaryReportData(null);
            } else {
                setReportData(null);
            }
            setLoadingResult(false);
            return;
        }
        if (!curentReportJson.data || !curentReportJson.data.overall) {
            cogoToast.warn('No report found');
            if (isSecondary) {
                setSecondaryReportData(null);
            } else {
                setReportData(null);
            }
            setLoadingResult(false);
            return;
        }
        if (isSecondary) {
            setSecondaryReportData(curentReportJson.data);
        } else {
            setReportData(curentReportJson.data);
        }
        setLoadingResult(false);
        return;
    };
    const getTitle = (
        reportType: string | undefined,
        reportFilter: string | undefined
    ) => {
        if (reportFilter) {
            return reportFilter;
        }
        if (reportType) {
            if (reportType === 'product') {
                return 'Product';
            } else if (reportType === 'compliance') {
                return 'Compliance';
            }
            return '';
        }
        return '';
    };
    const getSVGDef = (overallScore: any) => {
        if (overallScore >= 75) {
            return '#excellent';
        } else if (overallScore > 50 && overallScore < 75) {
            return '#medium';
        } else {
            return '#low';
        }
    };
    const getRowstyle = (key: any) => {
        if (key.percentage >= 75) {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#56d41b'
            };
        } else if (key.percentage > 50 && key.percentage < 75) {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#d38614'
            };
        } else {
            return {
                width: `${Math.round(key.percentage)}%`,
                backgroundColor: '#e81111'
            };
        }
    };
    const getDateYear = (dateRange: any) => {
        let start = moment(dateRange.startDate).format('DD MMM');
        let end = moment(dateRange.endDate).format('DD MMM');
        let year = moment(dateRange.end).format('YYYY');
        setDateYear({
            start,
            end,
            year,
        });
    };
    if (sessionStorage.getItem('isBack')) {
        // @ts-ignore
        const data = JSON.parse(sessionStorage.getItem('dataBack'));
        setDateRange({
            // @ts-ignore
            startDate: moment(data.formatedStartDate),
            // @ts-ignore
            endDate: moment(data.formatedEndDate),
        });
        setViewByOptionSelected({
            value: data.viewByOptionSelected,
            label: '',
        });
        setViewByStore(data.viewByStore);
        setIsResult(true);
        sessionStorage.clear();
    }
    useEffect(() => {
        if (!dateRange.startDate || !dateRange.endDate) {
            return;
        }
        if (!viewByOptionSelected.value) {
            return;
        }
        setTimeout(() => {
            const startDate = dateRange.startDate;
            const endDate = dateRange.endDate;
            if (startDate && endDate) {
                getDateYear(dateRange);
                const formatedStartDate = moment(startDate).format(
                    'YYYY-MM-DD'
                );
                const formatedEndDate = moment(endDate).format('YYYY-MM-DD');
                const reportView =
                    reportType === 'promotion'
                        ? reportType
                        : selectedPlanogramTypeOption.value;
                if (window.screen && window.screen.width > 800) {
                    if (reportType === 'promotion') {
                        getCurrentReport(
                            viewByOptionSelected.value,
                            viewByStore,
                            reportType,
                            reportFilter,
                            formatedStartDate,
                            formatedEndDate
                        );
                    } else {
                        getCurrentReport(
                            viewByOptionSelected.value,
                            viewByStore,
                            'product',
                            reportFilter,
                            formatedStartDate,
                            formatedEndDate
                        );
                        getCurrentReport(
                            viewByOptionSelected.value,
                            viewByStore,
                            'compliance',
                            secondaryReportFilter,
                            formatedStartDate,
                            formatedEndDate,
                            true
                        );
                    }
                } else {
                    setIsResult(true);
                    sessionStorage.setItem(
                        'backLink',
                        history.location.pathname
                    );
                    if (reportFilter) {
                        sessionStorage.setItem('isBack', '1');
                        sessionStorage.setItem(
                            'dataBack',
                            JSON.stringify({
                                viewByOptionSelected:
                                viewByOptionSelected.value,
                                viewByStore,
                                reportView,
                                formatedStartDate,
                                formatedEndDate,
                            })
                        );
                        history.push({
                            pathname: '/report/planogram/filter',
                            state: {
                                backLink: '/report/planogram',
                                params: {
                                    viewByOptionSelected:
                                    viewByOptionSelected.value,
                                    viewByStore,
                                    reportView,
                                    reportFilter,
                                    formatedStartDate,
                                    formatedEndDate,
                                },
                            },
                        });
                    }
                    getCurrentReport(
                        viewByOptionSelected.value,
                        viewByStore,
                        reportView,
                        reportFilter,
                        formatedStartDate,
                        formatedEndDate
                    );
                }
            }
            return;
        }, 2000);
    }, [
        dateRange,
        viewByOptionSelected,
        reportFilter,
        selectedPlanogramTypeOption,
        secondaryReportFilter,
    ]);
    useEffect(() => {
        initiateReportDetails();
    }, []);

    const getPrimaryReportDivStyle = () => {
        if (reportType === 'promotion') {
            return {};
        }
        if (window.screen && window.screen.width > 800) {
            return { width: '50%', display: 'inline-block' };
        } else {
            return {};
        }
    };

    const getSecondaryReportDivStyle = () => {
        if (
            reportType === 'planogram' &&
            window.screen &&
            window.screen.width > 800
        ) {
            return { width: '50%', display: 'inline-block' };
        } else {
            return { display: 'none' };
        }
    };

    // @ts-ignore
    return (
        <div className='reports'>
            <svg style={{ position: 'absolute' }}>
                <defs>
                    <linearGradient
                        id='excellent'
                        x1='0%'
                        y1='0%'
                        x2='100%'
                        y2='0%'
                    >
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='50%' stopColor='#437ee7' />
                        <stop offset='100%' stopColor='#4586ec' />
                    </linearGradient>
                    <linearGradient
                        id='medium'
                        x1='0%'
                        y1='0%'
                        x2='100%'
                        y2='0%'
                    >
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='50%' stopColor='#4167d9' />
                        <stop offset='100%' stopColor='#4586ec' />
                    </linearGradient>
                    <linearGradient id='low' x1='0%' y1='0%' x2='100%' y2='0%'>
                        <stop offset='0%' stopColor='#3920ad' />
                        <stop offset='100%' stopColor='#4167d9' />
                    </linearGradient>
                </defs>
            </svg>
            <div className='content__content reports__content'>
                {!isResult && (
                    <div className='reports__controls mobile'>
                        {reportType === 'planogram' && (
                            <>
                                <div className='reports__label'>
                                    Report Filters
                                </div>
                                <div className='reports__content reports__viewBy__dropdown'>
                                    <Select
                                        value={selectedPlanogramTypeOption}
                                        styles={customStyles}
                                        onChange={(selectedOption: any) => {
                                            handleChangePlanogramType(
                                                selectedOption
                                            );
                                        }}
                                        options={constants.PLANO_GRAM_TYPE_OPTIONS}
                                    />
                                </div>
                            </>
                        )}
                        {userRole === 'headquarter' ? (
                            <>
                                <div className='reports__content reports__viewBy__dropdown'>
                                    <Select
                                        value={viewTypeOptionSelected}
                                        styles={customStyles}
                                        onChange={(selectedOption: any) => {
                                            handleClickChangeViewBy(
                                                selectedOption
                                            );
                                        }}
                                        options={constants.VIEW_TYPE_OPTIONS}
                                    />
                                </div>
                                <div className='reports__content reports__viewBy__dropdown'>
                                    <Select
                                        value={viewByOptionSelected}
                                        styles={customStyles}
                                        onChange={(selectedOption: any) => {
                                            setViewByOptionSelected(
                                                selectedOption
                                            );
                                        }}
                                        options={viewByOptions}
                                    />
                                </div>
                            </>
                        ) : null}
                        <div className='reports__controls__item reports__content__item reports__content__item--daterange'>
                            <div className='reports__controls__item__title reports__content__item__title--daterange reports__label'>
                                Select a timeframe
                            </div>
                            <div className='reports__controls__item__content reports__content__item__content--daterange'>
                                <div className='reports__controls__datePicker'>
                                    <div className='reports__controls__datePicker--item'>
                                        {
                                            // @ts-ignore
                                            dateRange.startDate && moment(dateRange.startDate).format('DD MMM, YY')
                                        }
                                    </div>
                                    <div className='reports__controls__datePicker--item'>
                                        {
                                            // @ts-ignore
                                            dateRange.endDate && moment(dateRange.endDate).format('DD MMM, YY')
                                        }
                                    </div>
                                </div>
                                <CalendarPicker
                                    value={dateValue}
                                    onSelect={(value) => selectCalendarValue(value)}
                                    singleDateRange
                                    className='calendar_date_range_picker'
                                    firstOfWeek={1}
                                    selectionType="range"
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className='reports__controls desktop'>
                    {userRole === 'headquarter' ? (
                        <div className='reports__content reports__viewBy'>
                            <Select
                                value={viewTypeOptionSelected}
                                styles={customStyles}
                                onChange={(selectedOption: any) => {
                                    handleClickChangeViewBy(selectedOption);
                                }}
                                options={constants.VIEW_TYPE_OPTIONS}
                            />
                            <Select
                                value={viewByOptionSelected}
                                styles={customStyles}
                                onChange={(selectedOption: any) => {
                                    setViewByOptionSelected(selectedOption);
                                }}
                                options={viewByOptions}
                            />
                        </div>
                    ) : null}
                    <div className='reports__controls__item reports__content__item reports__content__item--daterange'>
                        <div className='reports__controls__item__title reports__content__item__title--daterange'>
                            Select a timeframe
                        </div>
                        <div className='reports__controls__item__content reports__content__item__content--daterange'>
                            <DateRangePicker
                                startDatePlaceholderText='Start'
                                startDate={startDate}
                                onDatesChange={handleOnDateChange}
                                endDatePlaceholderText='End'
                                endDate={endDate}
                                isOutsideRange={() => false}
                                numberOfMonths={1}
                                displayFormat='MMM D'
                                showClearDates={true}
                                focusedInput={focus}
                                onFocusChange={(focus: any) => setFocus(focus)}
                                startDateId='startDateMookh'
                                endDateId='endDateMookh'
                                minimumNights={0}
                                withPortal={true}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='reports__content__item reports__content__item--title'>
                    {getTitle(reportType, reportFilter)}
                </div> */}
                <div className='reports__content__chart reports__content__desktop'>
                    {
                        <div style={getPrimaryReportDivStyle()}>
                            {reportData ? (
                                <div className='reports__content__item reports__content__item--all'>
                                    {reportType === 'planogram' && (
                                        <Fragment>
                                            {(reportFilter && (
                                                <p className='reports__planogram_primary_heading'>
                                                    Compliance for{' '}
                                                    {reportFilter}
                                                </p>
                                            )) || (
                                                <p className='reports__planogram_primary_heading'>
                                                    Results by Product Category
                                                </p>
                                            )}
                                        </Fragment>
                                    )}
                                    {/* {reportType === 'planogram' && (
                                        <Fragment>
                                            {(reportFilter && (
                                                <Fragment>
                                                    {(selectedPlanogramTypeOption.value ===
                                                        'product' && (
                                                        <p className='reports__planogram_primary_heading_mobile'>
                                                            Compliance for{' '}
                                                            {reportFilter}
                                                        </p>
                                                    )) || (
                                                        <p className='reports__planogram_primary_heading_mobile'>
                                                            {reportFilter}{' '}
                                                            Compliance Results
                                                        </p>
                                                    )}
                                                </Fragment>
                                            )) || (
                                                <p className='reports__planogram_primary_heading_mobile'>
                                                    Results by{' '}
                                                    {
                                                        selectedPlanogramTypeOption.value
                                                    }{' '}
                                                    Category
                                                </p>
                                            )}
                                        </Fragment>
                                    )} */}
                                    {reportData?.overall ? (
                                        <div className='reports__overall'>
                                            <CircularProgressbar
                                                value={reportData.overall}
                                                text={`${Math.round(
                                                    reportData.overall
                                                )}%`}
                                                styles={buildStyles({
                                                    trailColor: '#3920ad',
                                                    textColor: `url(${getSVGDef(
                                                        reportData.overall
                                                    )})`,
                                                    pathColor: `url(${getSVGDef(
                                                        reportData.overall
                                                    )})`,
                                                })}
                                            />
                                        </div>
                                    ) : null}
                                    {reportData?.category_wise_data ? (
                                        <div className='reports__category'>
                                            {Object.keys(
                                                reportData.category_wise_data
                                            ).map((key) => {
                                                return (
                                                    <div
                                                        className='reports__category__item'
                                                        key={key}
                                                    >
                                                        <a
                                                            className='reports__category__item__link'
                                                            onClick={() => {
                                                                if (
                                                                    reportFilter ||
                                                                    reportType ===
                                                                    'promotion'
                                                                ) {
                                                                    return null;
                                                                }
                                                                const filter: any = key;
                                                                setReportFilter(
                                                                    filter
                                                                );
                                                            }}
                                                        >
                                                            <div className='reports__category__item__content'>
                                                                <div className='reports__category__item__title'>
                                                                    <div className='reports__category__item__title__key'>
                                                                        {key}
                                                                    </div>
                                                                    <div className='reports__category__item__title__result'>
                                                                        {Math.round(
                                                                            reportData
                                                                                .category_wise_data[
                                                                                key
                                                                                ]
                                                                                ?.percentage
                                                                        )}
                                                                        %
                                                                    </div>
                                                                </div>
                                                                <div className='reports__category__item__bar'>
                                                                    <div
                                                                        className='reports__category__item__bar__content'
                                                                        style={getRowstyle(
                                                                            reportData
                                                                                .category_wise_data[
                                                                                key
                                                                                ]
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {reportFilter ||
                                                            reportType ===
                                                            'promotion' ? null : (
                                                                <div className='reports__category__item__icon'>
                                                                    <img
                                                                        src={
                                                                            rightIcon
                                                                        }
                                                                        alt=''
                                                                    />
                                                                </div>
                                                            )}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className='reports__content__item--none'>
                                    No reports found
                                </div>
                            )}
                        </div>
                    }

                    {reportType !== 'promotion' && (
                        <div style={getSecondaryReportDivStyle()}>
                            {secondaryReportData ? (
                                <div className='reports__content__item reports__content__item--all'>
                                    <Fragment>
                                        {(secondaryReportFilter && (
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {secondaryReportFilter}{' '}
                                                Compliance Results
                                            </p>
                                        )) || (
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                Results by Compliance Category{' '}
                                            </p>
                                        )}
                                    </Fragment>
                                    {secondaryReportData?.overall ? (
                                        <div className='reports__overall'>
                                            <CircularProgressbar
                                                value={
                                                    secondaryReportData.overall
                                                }
                                                text={`${Math.round(
                                                    secondaryReportData.overall
                                                )}%`}
                                                styles={buildStyles({
                                                    trailColor: '#F4F8FF',
                                                    textColor: `url(${getSVGDef(
                                                        secondaryReportData.overall
                                                    )})`,
                                                    pathColor: `url(${getSVGDef(
                                                        secondaryReportData.overall
                                                    )})`,
                                                })}
                                            />
                                        </div>
                                    ) : null}
                                    {secondaryReportData?.category_wise_data ? (
                                        <div className='reports__category'>
                                            {Object.keys(
                                                secondaryReportData.category_wise_data
                                            ).map((key) => {
                                                return (
                                                    <div className='reports__category__item'>
                                                        <a
                                                            className='reports__category__item__link'
                                                            onClick={() => {
                                                                if (
                                                                    secondaryReportFilter ||
                                                                    reportType ===
                                                                    'promotion'
                                                                ) {
                                                                    return null;
                                                                }
                                                                const filter: any = key;
                                                                setSecondaryReportFilter(
                                                                    filter
                                                                );
                                                            }}
                                                        >
                                                            <div className='reports__category__item__content'>
                                                                <div className='reports__category__item__title'>
                                                                    <div className='reports__category__item__title__key'>
                                                                        {key}
                                                                    </div>
                                                                    <div className='reports__category__item__title__result'>
                                                                        {Math.round(
                                                                            secondaryReportData
                                                                                .category_wise_data[
                                                                                key
                                                                                ]
                                                                                ?.percentage
                                                                        )}
                                                                        %
                                                                    </div>
                                                                </div>
                                                                <div className='reports__category__item__bar'>
                                                                    <div
                                                                        className='reports__category__item__bar__content'
                                                                        style={getRowstyle(
                                                                            secondaryReportData
                                                                                .category_wise_data[
                                                                                key
                                                                                ]
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {reportFilter ||
                                                            reportType ===
                                                            'promotion' ? null : (
                                                                <div className='reports__category__item__icon'>
                                                                    <img
                                                                        src={
                                                                            rightIcon
                                                                        }
                                                                        alt=''
                                                                    />
                                                                </div>
                                                            )}
                                                        </a>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div className='reports__content__item--none'>
                                    No reports found
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {isResult && !loadingResult ? (
                    <div className='reports__content__chart reports__content__mobile'>
                        {
                            <div style={getPrimaryReportDivStyle()}>
                                {reportData ? (
                                    <div className='reports__content__item reports__content__item--all'>
                                        {/* {reportType === 'planogram' && (
                                            <Fragment>
                                                {(reportFilter && (
                                                    <p className='reports__planogram_primary_heading'>
                                                        Compliance for{' '}
                                                        {reportFilter}
                                                    </p>
                                                )) || (
                                                    <p className='reports__planogram_primary_heading'>
                                                        Compliance by Product Category
                                                    </p>
                                                )}
                                            </Fragment>
                                        )} */}
                                        {reportType === 'planogram' && (
                                            <Fragment>
                                                {(reportFilter && (
                                                    <Fragment>
                                                        <div className='reports__controls__item reports__content__item reports__content__item--daterange'>
                                                            <div className='reports__controls__item__title reports__content__item__title--daterange reports__label'>
                                                                Select a
                                                                timeframe
                                                            </div>
                                                            <div className='reports__controls__item__content reports__content__item__content--daterange'>
                                                                <DateRangePicker
                                                                    startDatePlaceholderText='Start'
                                                                    startDate={
                                                                        startDate
                                                                    }
                                                                    onDatesChange={
                                                                        handleOnDateChange
                                                                    }
                                                                    endDatePlaceholderText='End'
                                                                    endDate={
                                                                        endDate
                                                                    }
                                                                    isOutsideRange={() =>
                                                                        false
                                                                    }
                                                                    numberOfMonths={
                                                                        1
                                                                    }
                                                                    displayFormat='MMM D'
                                                                    showClearDates={
                                                                        true
                                                                    }
                                                                    focusedInput={
                                                                        secondDatepickerFocus
                                                                    }
                                                                    onFocusChange={(
                                                                        focus: any
                                                                    ) =>
                                                                        setSecondDatepickerFocusFocus(
                                                                            focus
                                                                        )
                                                                    }
                                                                    startDateId='startDateMookh'
                                                                    endDateId='endDateMookh'
                                                                    minimumNights={
                                                                        0
                                                                    }
                                                                    withPortal={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        {(selectedPlanogramTypeOption.value ===
                                                            'product' && (
                                                                <React.Fragment>
                                                                    <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                                        Compliance
                                                                        for{' '}
                                                                        {
                                                                            reportFilter
                                                                        }
                                                                    </p>
                                                                </React.Fragment>
                                                            )) || (
                                                            <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                                Compliance for{' '}
                                                                {reportFilter}
                                                            </p>
                                                        )}
                                                    </Fragment>
                                                )) || (
                                                    <React.Fragment>
                                                        <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_title'>
                                                            Compliance by{' '}
                                                            {selectedPlanogramTypeOption.value ===
                                                            'product'
                                                                ? 'Product'
                                                                : 'KPIs'}{' '}
                                                            Category
                                                        </p>
                                                        <p className='reports__planogram_primary_heading_mobile reports__planogram_primary_heading_mobile_period'>
                                                            Report Period:{' '}
                                                            {dateYear.start}
                                                            {' - '}
                                                            {dateYear.end}
                                                            {', '}
                                                            {dateYear.year}
                                                        </p>
                                                    </React.Fragment>
                                                )}
                                            </Fragment>
                                        )}
                                        {reportData?.overall ? (
                                            <div className='reports__overall'>
                                                <CircularProgressbar
                                                    value={reportData.overall}
                                                    strokeWidth={3}
                                                    counterClockwise
                                                    text={`${Math.round(
                                                        reportData.overall
                                                    )}%`}
                                                    styles={buildStyles({
                                                        trailColor: '#F4F8FF',
                                                        // textColor: `url(${getSVGDef(
                                                        //     reportData.overall
                                                        // )})` ,
                                                        textColor: '#121E34',
                                                        pathColor: `url(${getSVGDef(
                                                            reportData.overall
                                                        )})`,
                                                    })}
                                                />
                                            </div>
                                        ) : null}
                                        {reportData?.category_wise_data ? (
                                            <div className='reports__category'>
                                                {Object.keys(
                                                    reportData.category_wise_data
                                                ).map((key) => {
                                                    return (
                                                        <div
                                                            className='reports__category__item'
                                                            key={key}
                                                        >
                                                            <a
                                                                className='reports__category__item__link'
                                                                onClick={() => {
                                                                    if (
                                                                        reportFilter ||
                                                                        reportType ===
                                                                        'promotion'
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    const filter: any = key;
                                                                    setReportFilter(
                                                                        filter
                                                                    );
                                                                }}
                                                            >
                                                                <div className='reports__category__item__content'>
                                                                    <div className='reports__category__item__title'>
                                                                        <div className='reports__category__item__title__key'>
                                                                            {
                                                                                key
                                                                            }
                                                                        </div>
                                                                        <div className='reports__category__item__title__result'>
                                                                            {Math.round(
                                                                                reportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                    ]
                                                                                    ?.percentage
                                                                            )}
                                                                            %
                                                                        </div>
                                                                    </div>
                                                                    <div className='reports__category__item__bar'>
                                                                        <div
                                                                            className='reports__category__item__bar__content'
                                                                            style={getRowstyle(
                                                                                reportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                    ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {reportFilter ||
                                                                reportType ===
                                                                'promotion' ? null : (
                                                                    <div className='reports__category__item__icon'>
                                                                        <img
                                                                            src={
                                                                                rightIcon
                                                                            }
                                                                            alt=''
                                                                        />
                                                                    </div>
                                                                )}
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className='reports__content__item--none'>
                                        No reports found
                                    </div>
                                )}
                            </div>
                        }

                        {reportType !== 'promotion' && (
                            <div style={getSecondaryReportDivStyle()}>
                                {secondaryReportData ? (
                                    <div className='reports__content__item reports__content__item--all'>
                                        <Fragment>
                                            {(secondaryReportFilter && (
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {secondaryReportFilter}{' '}
                                                    Compliance Results
                                                </p>
                                            )) || (
                                                <p
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                    }}
                                                >
                                                    Results by Compliance
                                                    Category{' '}
                                                </p>
                                            )}
                                        </Fragment>
                                        {secondaryReportData?.overall ? (
                                            <div className='reports__overall'>
                                                <CircularProgressbar
                                                    value={
                                                        secondaryReportData.overall
                                                    }
                                                    strokeWidth={3}
                                                    counterClockwise
                                                    text={`${Math.round(
                                                        secondaryReportData.overall
                                                    )}%`}
                                                    styles={buildStyles({
                                                        trailColor: '#F4F8FF',
                                                        // textColor: `url(${getSVGDef(
                                                        //     secondaryReportData.overall
                                                        // )})`,
                                                        textColor: '#121E34',
                                                        pathColor: `url(${getSVGDef(
                                                            secondaryReportData.overall
                                                        )})`,
                                                    })}
                                                />
                                            </div>
                                        ) : null}
                                        {secondaryReportData?.category_wise_data ? (
                                            <div className='reports__category'>
                                                {Object.keys(
                                                    secondaryReportData.category_wise_data
                                                ).map((key) => {
                                                    return (
                                                        <div className='reports__category__item'>
                                                            <a
                                                                className='reports__category__item__link'
                                                                onClick={() => {
                                                                    if (
                                                                        secondaryReportFilter ||
                                                                        reportType ===
                                                                        'promotion'
                                                                    ) {
                                                                        return null;
                                                                    }
                                                                    const filter: any = key;
                                                                    setSecondaryReportFilter(
                                                                        filter
                                                                    );
                                                                    // history.push(`/report/${reportType}/${filter}`);
                                                                }}
                                                            >
                                                                <div className='reports__category__item__content'>
                                                                    <div className='reports__category__item__title'>
                                                                        <div className='reports__category__item__title__key'>
                                                                            {
                                                                                key
                                                                            }
                                                                        </div>
                                                                        <div className='reports__category__item__title__result'>
                                                                            {Math.round(
                                                                                secondaryReportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                    ]
                                                                                    ?.percentage
                                                                            )}
                                                                            %
                                                                        </div>
                                                                    </div>
                                                                    <div className='reports__category__item__bar'>
                                                                        <div
                                                                            className='reports__category__item__bar__content'
                                                                            style={getRowstyle(
                                                                                secondaryReportData
                                                                                    .category_wise_data[
                                                                                    key
                                                                                    ]
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {reportFilter ||
                                                                reportType ===
                                                                'promotion' ? null : (
                                                                    <div className='reports__category__item__icon'>
                                                                        <img
                                                                            src={
                                                                                rightIcon
                                                                            }
                                                                            alt=''
                                                                        />
                                                                    </div>
                                                                )}
                                                            </a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className='reports__content__item--none'>
                                        No reports found
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ) : isResult && loadingResult ? (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Report;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import cogoToast from 'cogo-toast';
import { useHistory } from 'react-router';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import { LoadingButton, Button } from '../../components/button/button-container';
import { REACT_APP_API_ENDPOINT } from '../../lib/getuserdetails';
import { getCurrentTimeZone } from '../../utility/time.utility';
import constants from '../../lib/constants';
import './createauditschedule.scss';
import secureStorage from "../../lib/localStorage";
import { getClustersByClient } from "../../lib/apiWrappers";

const CreateAuditSchedule: React.FC = () => {
    const history = useHistory();
    const [createAuditScheduleLoading, setCreateAuditScheduleLoading] = useState(false);
    const [idToken, setIdToken] = useState<string | null>(null);
    const [userRole, setUserRole] = useState<string | undefined>('associate');
    const [auditScheduleTitle, setAuditScheduleTitle] = useState("");
    const [auditScheduleStartTime, setAuditScheduleStartTime] = useState(new Date());
    const [auditScheduleEndTime, setAuditScheduleEndTime] = useState(new Date());
    const [recurrenceOptionSelected, setRecurrenceOptionSelected] = useState<{ value: string, label: string }>(constants.RECURRENCE_OPTIONS[0]);
    const [clusterOptions, setClusterOptions] = useState<{ value: string, label: string }[]>([]);
    const [clusterOptionSelected, setClusterOptionSelected] = useState<{ value: string, label: string }>({
        value: '',
        label: 'All clusters'
    });

    const getUserDetails = () => {
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);
        if (!userToken || !userRole) {
            return;
        }

        setIdToken(userToken);
        setUserRole(userRole);
    };

    const loadClusterOptions = async () => {
        if (!idToken || userRole !== 'headquarter') {
            return;
        }

        const claimsData = secureStorage.getItem(constants.CLAIMS);
        const clientId = claimsData ?.app_metadata ?._client ?? undefined;
        if (!clientId) {
            cogoToast.error("You are not associated with any company, please contact support");
            history.push("/home");
            return;
        }

        let userClustersOptions: any = [{
            value: '',
            label: 'All clusters'
        }];
        const clusters = await getClustersByClient(clientId, idToken);
        clusters.forEach(cluster => {
            if (cluster) {
                userClustersOptions.push({
                    value: cluster._id,
                    label: cluster.name
                });
            }
        });
        if (userClustersOptions.length == 0) {
            cogoToast.error("No Cluster could be found associated with you. Please contact support.");
            return;
        }
        setClusterOptions(userClustersOptions);
        setClusterOptionSelected(userClustersOptions[0]);
    };

    const submitAuditSchedule = async () => {
        if (!idToken) {
            return;
        }
        setCreateAuditScheduleLoading(true);

        if (auditScheduleTitle === '') {
            cogoToast.error('Please provide a title for the Audit schedule');
            setCreateAuditScheduleLoading(false);
            return;
        }
        if (recurrenceOptionSelected.value === '') {
            cogoToast.error('Please choose a recurrence option');
            setCreateAuditScheduleLoading(false);
            return;
        }

        const endpoint = `${REACT_APP_API_ENDPOINT}/schedule-task`;
        const startTime = new Date(auditScheduleStartTime);
        const endTime = new Date(auditScheduleEndTime);
        const requestBody = {
            title: auditScheduleTitle,
            start_time: String(moment(startTime).utc()),
            end_time: String(moment(endTime).utc()),
            recurrence: recurrenceOptionSelected.value,
            cluster_id: clusterOptionSelected.value
        };
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const auditScheduleCreateResult = await response.json();
        if (auditScheduleCreateResult.success) {
            setCreateAuditScheduleLoading(false);
            cogoToast.success(`Audit Schedule created`);
        }

        if ((!auditScheduleCreateResult) || !(auditScheduleCreateResult.success)) {
            cogoToast.error(`The audit schedule could not be created`);
            setCreateAuditScheduleLoading(false);
            return;
        }
        if (auditScheduleCreateResult.data.scheduleTaskId) {
            history.push(`/auditschedule/${auditScheduleCreateResult.data.scheduleTaskId}`);
            return;
        }
        setCreateAuditScheduleLoading(false);
    };

    useEffect(() => {
        getUserDetails()
    }, []);

    useEffect(() => {
        loadClusterOptions()
    }, [idToken]);


    return (
        <div className="createauditschedule">
            <div className="createauditschedule__content">
                <div className='createauditschedule__content__item'>
                    <label className='pure-material-textfield-outlined'>
                        <input placeholder=" "
                               value={auditScheduleTitle}
                               onChange={(e) => setAuditScheduleTitle(e.target.value)}
                               onBlur={(e) => setAuditScheduleTitle(e.target.value)}
                        />
                        <span>Title</span>
                    </label>
                </div>
                <div className="createauditschedule__content__row">
                    <div className='createauditschedule__content__item col'>
                        <div className="createauditschedule__due-date__label">
                            Start time ({getCurrentTimeZone()})
                        </div>
                        <div className="createauditschedule__due-date__picker">
                            <DatePicker
                                selected={auditScheduleStartTime}
                                onChange={(date: any) => setAuditScheduleStartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="h:mm aa"
                            />
                        </div>
                    </div>
                    <div className='createauditschedule__content__item col'>
                        <div className="createauditschedule__due-date__label">
                            End time ({getCurrentTimeZone()})
                        </div>
                        <div className="createauditschedule__due-date__picker">
                            <DatePicker
                                selected={auditScheduleEndTime}
                                onChange={(date: any) => setAuditScheduleEndTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="h:mm aa"
                            />
                        </div>
                    </div>
                </div>
                <div className="createauditschedule__content__row">
                    <div className='createauditschedule__content__item col'>
                        <div className="createauditschedule__due-date__label">
                            Start date
                        </div>
                        <div className="createauditschedule__due-date__picker">
                            <DatePicker
                                selected={auditScheduleStartTime}
                                onChange={(date: any) => setAuditScheduleStartTime(date)}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy"
                            />
                        </div>
                    </div>
                    <div className='createauditschedule__content__item col'>
                        <div className="createauditschedule__due-date__label">
                            End date
                        </div>
                        <div className="createauditschedule__due-date__picker">
                            <DatePicker
                                selected={auditScheduleEndTime}
                                onChange={(date: any) => setAuditScheduleEndTime(date)}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MMMM d, yyyy"
                            />
                        </div>
                    </div>
                </div>
                <div className='createauditschedule__content__item'>
                    <div className="createauditschedule__due-date__label">
                        Recurrence
                    </div>
                    <div className='createauditschedule__viewBy__dropdown'>
                        <Select
                            value={recurrenceOptionSelected}
                            styles={constants.DROPDOWN_STYLES}
                            onChange={(selectedOption: any) => {
                                setRecurrenceOptionSelected(selectedOption)
                            }}
                            options={constants.RECURRENCE_OPTIONS}
                        />
                    </div>
                </div>
                <div className='createauditschedule__content__item'>
                    <div className="createauditschedule__due-date__label">
                        Cluster
                    </div>
                    <div className='createauditschedule__viewBy__dropdown'>
                        <Select
                            value={clusterOptionSelected}
                            styles={constants.DROPDOWN_STYLES}
                            onChange={(selectedOption: any) => {
                                setClusterOptionSelected(selectedOption)
                            }}
                            options={clusterOptions}
                        />
                    </div>
                </div>

                <div className="createauditschedule__content__item">
                    <div className="createauditschedule__cta">
                        {
                            (createAuditScheduleLoading) ?
                                <LoadingButton
                                    className="btn btn--primary btn--full-width"
                                    onClick={() => { return null; }}
                                /> :
                                <Button
                                    className="btn btn--primary btn--full-width"
                                    onClick={submitAuditSchedule}
                                    buttonText="Submit"
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default CreateAuditSchedule;

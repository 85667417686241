import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useAuth0 } from '../../lib/auth';
import { PrivateRoute } from '../privateroute/privateroute-container';
import {
    Registration,
    Home,
    Login,
    AuditTasks,
    CustomTasks,
    PromotionTasks,
    AuditSchedule,
    AuditSchedules,
    Task,
    CreateCustomTask,
    CreatePromotionTask,
    ReportSelectPre,
    ReportSelect,
    Report,
    CreateAuditSchedule,
    Settings,
    SuccessDeleted,
    SuccessCreated,
    SuccessCompleted,
    ReportFilter,
    NonCompliance
} from '../../routes';

import loadingIcon from '../../images/loading-2.gif';
import './app.scss';
import '../../styles/_global.scss';

const App: React.FC = () => {
    const { isInitializing } = useAuth0();
    if (isInitializing) {
        return (
            <div className='app app--loading'>
                <img src={loadingIcon} alt='' />
            </div>
        );
    }
    return (
        <div className='app'>
            <Router>
                <Switch>
                    <Route path='/' exact component={Login} />
                    <PrivateRoute
                        path='/registration'
                        title='Registration'
                        component={Registration}
                    />
                    <PrivateRoute path='/home' title='Home' component={Home} />
                    <PrivateRoute
                        path='/audittasks'
                        title='All Planogram Audits'
                        isBack={true}
                        backLink='/home'
                        component={AuditTasks}
                    />
                    <PrivateRoute
                        path='/auditschedules'
                        title='All Planogram Audits Schedule'
                        isBack={true}
                        backLink='/audittasks'
                        component={AuditSchedules}
                    />
                    <PrivateRoute
                        path='/customtasks'
                        title='All Custom Tasks'
                        isBack={true}
                        backLink='/home'
                        component={CustomTasks}
                    />
                    <PrivateRoute
                        path='/promotiontasks'
                        title='All Promotion Tasks'
                        isBack={true}
                        backLink='/home'
                        component={PromotionTasks}
                    />
                    <PrivateRoute
                        path='/createauditschedule'
                        title='New Planogram Audit'
                        isBack={true}
                        backLink='/auditschedules'
                        component={CreateAuditSchedule}
                    />
                    <PrivateRoute
                        path='/auditschedule/:id'
                        title='Planogram Audit Schedule'
                        isBack={true}
                        backLink='/auditschedules'
                        component={AuditSchedule}
                    />
                    <PrivateRoute
                        path='/audittask/:id'
                        title='Planogram Audit'
                        isBack={true}
                        backLink='/audittasks'
                        component={Task}
                    />
                    <PrivateRoute
                        path='/customtask/:id'
                        title='Custom Task'
                        isBack={true}
                        backLink='/customtasks'
                        component={Task}
                    />
                    <PrivateRoute
                        path='/promotiontask/:id'
                        title='Promotion Task'
                        isBack={true}
                        backLink='/promotiontasks'
                        component={Task}

                    />
                    <PrivateRoute
                        path='/createcustomtask'
                        title='New Custom Task'
                        isBack={true}
                        backLink='/customtasks'
                        component={CreateCustomTask}
                    />
                    <PrivateRoute
                        path='/createpromotiontask'
                        title='New Promotion Task'
                        isBack={true}
                        backLink='/promotiontasks'
                        component={CreatePromotionTask}
                    />
                    <PrivateRoute
                        path='/reportselectpre'
                        title='Report'
                        isBack={true}
                        backLink='/home'
                        component={ReportSelectPre}
                    />
                    <PrivateRoute
                        path='/reportselect'
                        title='Report'
                        isBack={true}
                        backLink='/reportselectpre'
                        component={ReportSelect}
                    />
                    <PrivateRoute
                        path='/report/:type?/filter'
                        title='Report'
                        isBack={true}
                        backLink={null}
                        component={ReportFilter}
                    />
                    <PrivateRoute
                        path='/non-compliance'
                        title='Non-Compliance List'
                        isBack={true}
                        backLink={null}
                        component={NonCompliance}
                    />
                    <PrivateRoute
                        path='/report/:type?'
                        title='Report'
                        isBack={true}
                        backLink='/reportselectpre'
                        component={Report}
                    />

                    <PrivateRoute
                        path='/settings'
                        title='Settings'
                        component={Settings}
                        isBack={true}
                        backLink='/home'
                    />
                    <PrivateRoute
                        path='/deleted'
                        title={null}
                        component={SuccessDeleted}
                        isBack={true}
                        backLink={null}
                    />
                    <PrivateRoute
                        path='/created'
                        title={null}
                        component={SuccessCreated}
                        isBack={true}
                        backLink={null}
                    />
                    <PrivateRoute
                        path='/completed'
                        title={null}
                        component={SuccessCompleted}
                        isBack={true}
                        backLink={null}
                    />
                </Switch>
            </Router>
        </div>
    );
};

export default App;

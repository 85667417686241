import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import Modal from 'react-modal';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { REACT_APP_API_ENDPOINT } from '../../lib/getuserdetails';
import { getAuditScheduleDetails, fetchDelApi } from '../../lib/apiWrappers';
import { Button } from '../../components/button/button-container';
import { getCurrentTimeZone } from '../../utility/time.utility';
import secureStorage from '../../lib/localStorage';
import constants from '../../lib/constants';
import cancel from "../../images/cancel.png";
import './auditschedule.scss';

const customDeleteModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "70%",
        // height: '60vh', // <-- This sets the height
        overflow: 'scroll'
    }
};

const AuditSchedule: React.FC = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const [userRole, setUserRole] = useState("associate");
    const [auditScheduleLoading, setauditScheduleLoading] = useState(false);
    const [auditScheduleData, setAuditScheduleData] = useState<any>({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [auditScheduleId, setAuditScheduleId] = useState(undefined);

    const handleClickAuditScheduleDelete = async () => {
        setDeleteLoading(true);
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        if (!userToken) {
            cogoToast.error("Unauthorized user");
            return null;
        }
        const taskDeleteEndPoint = `${REACT_APP_API_ENDPOINT}/schedule-task/${auditScheduleId}`;
        const responseTaskDelete = await fetchDelApi(taskDeleteEndPoint, userToken);
        if (responseTaskDelete.success) {
            setDeleteLoading(false);
            history.push({
                pathname: '/deleted',
                state: {
                    title: 'Planogram Audit Schedule',
                    backLink: '/auditschedules',
                },
            });
            return;
        }
        setDeleteLoading(false);
        cogoToast.error(responseTaskDelete.data);
        return;
    };
    const getAuditSchedule = async () => {
        setauditScheduleLoading(true);
        const params: any = match.params;
        const auditScheduleId = params.id ?? "";
        if (!(auditScheduleId)) {
            cogoToast.error("No audit schedule found");
            return null;
        }
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);
        if (!userToken || !userRole) {
            cogoToast.error("Unauthorized user");
            return null;
        }
        setUserRole(userRole);
        setAuditScheduleId(auditScheduleId);
        const auditScheduleDetails = await getAuditScheduleDetails(auditScheduleId, userToken);
        if ((!auditScheduleDetails.success)) {
            cogoToast.error("No audit schedule found");
            return null;
        }
        setAuditScheduleData(auditScheduleDetails.data);
        setauditScheduleLoading(false);
    };
    useEffect(() => {
        getAuditSchedule();
    }, []);
    return (
        <div className="content content--auditschedule auditschedule">
            <div className={`auditschedule__loading-overlay--${auditScheduleLoading}`}>
                <div className="loadingSpinner" />
            </div>
            <div className="content__item auditschedule__item auditschedule__item--title">
                <div className="task__item--content">
                    {auditScheduleData.title}
                </div>
            </div>
            <div className="auditschedule__content__row">
                <div className='auditschedule__content__item col'>
                    <div className="auditschedule__due-date__label">
                        Start time ({getCurrentTimeZone()})
                    </div>
                    <div className="auditschedule__due-date__picker">
                        <div className="task__item--content">
                            {moment(auditScheduleData.start_time).format('HH:mm A')}
                        </div>
                    </div>
                </div>
                <div className='auditschedule__content__item col'>
                    <div className="auditschedule__due-date__label">
                        End time ({getCurrentTimeZone()})
                    </div>
                    <div className="auditschedule__due-date__picker">
                        <div className="task__item--content">
                            {moment(auditScheduleData.end_time).format('HH:mm A')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="auditschedule__content__row">
                <div className='auditschedule__content__item col'>
                    <div className="auditschedule__due-date__label">
                        Start date
                    </div>
                    <div className="auditschedule__due-date__picker">
                        <div className="task__item--content">
                            {moment(auditScheduleData.start_time).format('DD MMM, YYYY')}
                        </div>
                    </div>
                </div>
                <div className='auditschedule__content__item col'>
                    <div className="auditschedule__due-date__label">
                        End date
                    </div>
                    <div className="auditschedule__due-date__picker">
                        <div className="task__item--content">
                            {moment(auditScheduleData.end_time).format('DD MMM, YYYY')}
                        </div>
                    </div>
                </div>
            </div>
            <div className='auditschedule__content__item'>
                <div className="auditschedule__due-date__label">
                    Recurrence
                </div>
                <div className="auditschedule__due-date__picker">
                    <div className="task__item--content">
                        {auditScheduleData.recurrence}
                    </div>
                </div>
            </div>
            <div className='auditschedule__content__item'>
                <div className="auditschedule__due-date__label">
                    Cluster
                </div>
                <div className="auditschedule__due-date__picker">
                    <div className="task__item--content">
                        {auditScheduleData._cluster ? auditScheduleData._cluster.name : 'All clusters'}
                    </div>
                </div>
            </div>
            {
                (userRole === 'headquarter') ?
                    <div className="content__item task__item task__item--delete">
                        <Button
                            className="btn btn--link-danger"
                            onClick={() => setDeleteModalIsOpen(true)}
                            buttonText="Delete"
                        />
                    </div>
                    :
                    null
            }
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={() => setDeleteModalIsOpen(false)}
                style={customDeleteModalStyles}
                contentLabel="Confirm"
            >
                <div className="deletemodal">
                    <div className="deletemodal__header">
                        <div className="addactivitymodal__title">
                            Confirm
                        </div>
                        <div className="deletemodal__close" onClick={() => setDeleteModalIsOpen(false)}>
                            <img src={cancel} alt="" />
                        </div>
                    </div>
                    <div className="deletemodal__content">
                        Are you sure to delete the audit schedule?
                    </div>
                    <div className="deletemodal__cta">
                        {
                            (deleteLoading) ?
                                <button className="deletemodal__cta__button btn btn--danger" onClick={() => { return null; }}>
                                    <div className="loadingSpinner" />
                                </button>
                                :
                                <button className="deletemodal__cta__button btn btn--danger" onClick={handleClickAuditScheduleDelete}>
                                    Yes
                                </button>
                        }
                        <button className="deletemodal__cta__button btn" onClick={() => setDeleteModalIsOpen(false)}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
};

export default AuditSchedule;


const secureStorage = {
  setItem: (key: string, value: any) => {
    const data = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, data);
  },
  getItem: (key: string) => {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    } else {
      return null;
    }
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  }
};

export default secureStorage;

import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import secureStorage from '../../lib/localStorage';
import constants from '../../lib/constants';
import { getComplianceList } from '../../lib/apiWrappers';
import './style.scss';

const NonCompliance: React.FC = () => {
    const history = useHistory();
    interface IState {
        params: Object | any;
    }
    const state: IState | any = history.location.state;
    const [loadingResult, setLoadingResult] = useState(true);
    const [complianceList, setComplianceList ] = useState<any[]>([]);

    const getRowStyle = (percentage: number) => {
        if (percentage >= 75) {
            return {
                width: `${Math.round(percentage)}%`,
                backgroundColor: '#56d41b'
            };
        } else if (percentage > 50 && percentage < 75) {
            return {
                width: `${Math.round(percentage)}%`,
                backgroundColor: '#d38614'
            };
        } else {
            return {
                width: `${Math.round(percentage)}%`,
                backgroundColor: '#e81111'
            };
        }
    };

    const getUserDetails = async () => {
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);
        if (!userToken || !userRole) {
            return;
        }

        if (!sessionStorage.getItem('isBack') || !state || !state.params.label || !state.params.compliance) {
            history.push('/reportselectpre');
            return;
        }

        setLoadingResult(false);
        // @ts-ignore
        const data = JSON.parse(sessionStorage.getItem('dataBack'));
        const { viewByOptionSelected, viewByStore } = data;
        try {
            const response = await getComplianceList(viewByOptionSelected, viewByStore, userToken, state.params.compliance, state.params.label);
            const { data: { products }} = response;
            setComplianceList(products);

            setLoadingResult(true);
        } catch (err) {
            setLoadingResult(true);
        }
    };

    useEffect(() => {
        getUserDetails()
    }, []);

    return (
        <div>
            { state && state.params && (
                <div className='reports__category'>
                    <div className='reports__category__item'>
                        <div className='reports__category__item__content'>
                            <div className='reports__category__item__title'>
                                <div className='reports__category__item__title__key'>
                                    {state.params.compliance}
                                </div>
                                <div className='reports__category__item__title__result'>
                                    {Math.round(state.params.percentage ? state.params.percentage : 0)}%
                                </div>
                            </div>
                            <div className='reports__category__item__bar'>
                                <div
                                    className='reports__category__item__bar__content'
                                    style={getRowStyle(state.params.percentage ? state.params.percentage : 0)}
                                />
                            </div>
                        </div>
                    </div>
                        <div className='reports__category__compliance'>
                            <h1>List</h1>
                            {
                                loadingResult
                                    ? complianceList.length > 0 ? complianceList.map((item, index) => (
                                            <div className='compliance_item' key={String(index)}>
                                                <div>
                                                    <p>{index + 1}. {item.name}</p>
                                                    <p>SKU: {item.barcode}</p>
                                                </div>
                                                <div>
                                                    <img src={item.logo} alt='Product' />
                                                </div>
                                            </div>
                                        ))
                                        : (<div className='no-items'>No items</div>)
                                    : (
                                        <div style={{ textAlign: 'center', marginTop: '3em' }}>
                                            <CircularProgress />
                                        </div>
                                    )
                            }
                        </div>
                </div>
            )}
        </div>
    )
};

export default NonCompliance;

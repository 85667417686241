import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const SuccessCreated: React.FC = () => {
    return (
        <div className='content content__success content--created'>
            <div className='content--created--message content__success--message'>
                <CheckBoxIcon />
                <br />
                <span>Successfully Created</span>
            </div>
        </div>
    );
};

export default SuccessCreated;

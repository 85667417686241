import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const SuccessDeleted: React.FC = () => {
    return (
        <div className='content content__success content--deleted'>
            <div className='content--deleted--message content__success--message'>
                <CheckBoxIcon />
                <br />
                <span>Successfully Deleted</span>
            </div>
        </div>
    );
};

export default SuccessDeleted;

import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router';

import './home.scss';

import {useAuth0} from '../../lib/auth';
import {namespace} from '../../lib/getuserdetails';
import {Button} from '../../components/button/button-container';
import {getClient, getUser} from '../../lib/apiWrappers';
import homeImage from '../../images/home-illustration.png';
import SettingImage from '../../images/SettingsIcon.png';
import PromotionImage from '../../images/PromotionsIcon.png';
import ReportImage from '../../images/ReportsIcon.png';
import StoreImage from '../../images/StoresIcon.png';
import TaskImage from '../../images/TasksIcon.png';


const Home: React.FC = () => {
    const history = useHistory();
    const {getIdTokenClaims, logout} = useAuth0();
    const [userRole, setUserRole] = useState<string | undefined>(undefined);
    const [userName, setUserName] = useState(undefined);
    const [clientName, setClientName] = useState(undefined);
    const [clientLogo, setClientLogo] = useState(undefined);

    const onLogoutClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        await logout();
    };

    const initiateDetails = async () => {
        const claims: any = await getIdTokenClaims();
        const idToken = claims.__raw;
        const claimsData = claims?.[`${namespace}claims/`];
        const userRoles = claimsData?.roles ?? [];
        // get the user role
        // if more than one role, select the one with highest authority
        let userFinalRole = undefined;
        // TODO: get a better way to store the possible roles
        for (let role of ['associate', 'manager', 'headquarter']) {
            if (userRoles.includes(role)) {
                userFinalRole = role;
            }
        }
        setUserRole(userFinalRole);
        const userId = claimsData?.app_metadata?.uid;
        const userDetails = await getUser(userId, idToken);
        const userName = userDetails?.data?.full_name;
        setUserName(userName);
        const clientDetails = await getClient(idToken);
        const clientName = clientDetails?.data?.name;
        const clientLogo = clientDetails?.data?.logo;
        setClientName(clientName);
        setClientLogo(clientLogo);
    };
    useEffect(() => {
        initiateDetails();
    }, []);
    return (
        <div className='content content--home home'>
            <div className='content__header home__header'>
                <div className='home__header__paragraph home__header__paragraph--greeting'>
                    Welcome{userName ? `, ` : ''}
                </div>
                &nbsp;
                {userName ? (
                    <div className='home__header__paragraph home__header__paragraph--name'>
                        {userName}
                    </div>
                ) : (
                    <p></p>
                )}
            </div>
            <div className='home__illustration'>
                <div className='home__illustration__content'>
                    <img
                        className='client__illustration'
                        src={homeImage}
                        alt=''
                    />
                </div>
                <div className='home__illustration__client'>
                    {clientLogo ? (
                        <div className='home__illustration__client__logo'>
                            <img
                                className='client__logo'
                                src={clientLogo}
                                alt=''
                            />
                        </div>
                    ) : null}
                    {clientName ? (
                        <div className='home__illustration__client__name'>
                            {clientName}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className='content__content home__content home__content--cta'>
                <div className='home__cta'>
                    <div className='home__cta--badge'>
                        <span>2</span>
                    </div>
                    <Button
                        className='btn'
                        onClick={() => history.push('/audittasks')}
                        buttonText={
                            <Fragment>
                                <div>
                                    <img src={StoreImage} className='home__cta--icon' />
                                </div>
                                <p>Planogram Audits</p>
                            </Fragment>
                        }
                    />
                </div>
                <div className='home__cta'>
                    <div className='home__cta--badge'>
                        <span>2</span>
                    </div>
                    <Button
                        className='btn'
                        onClick={() => history.push('/customtasks')}
                        buttonText={
                            <Fragment>
                                <div>
                                    <img src={TaskImage} className='home__cta--icon' />
                                </div>
                                <p>Custom tasks</p>
                            </Fragment>
                        }
                    />
                </div>
                <div className='home__cta'>
                    <div className='home__cta--badge'>
                        <span>2</span>
                    </div>
                    <Button
                        className='btn'
                        onClick={() => history.push('/promotiontasks')}
                        buttonText={
                            <Fragment>
                                <div>
                                    <img src={PromotionImage} className='home__cta--icon' />
                                </div>
                                <p>Promotion tasks</p>
                            </Fragment>
                        }
                    />
                </div>
                {userRole !== 'associate' && (
                    <div className='home__cta'>
                        <div className='home__cta--badge'>
                            <span>2</span>
                        </div>
                        <Button
                            className='btn'
                            onClick={() => history.push('/reportselectpre')}
                            buttonText={
                                <Fragment>
                                    <div>
                                        <img src={ReportImage} className='home__cta--icon' />
                                    </div>
                                    <p>View reports</p>
                                </Fragment>
                            }
                        />
                    </div>
                )}
                {userRole !== 'associate' && (
                    <div className='home__cta'>
                        <div className='home__cta--badge'>
                            <span>2</span>
                        </div>
                        <Button
                            className='btn'
                            onClick={() => history.push('/settings')}
                            buttonText={
                                <Fragment>
                                    <div>
                                        <img src={SettingImage} className='home__cta--icon' />
                                    </div>
                                    <p>Settings</p>
                                </Fragment>
                            }
                        />
                    </div>
                )}
            </div>
            <div className="home__logout">
                <a href="" className="home__logout__link" onClick={onLogoutClick}>Logout</a>
            </div>
        </div>
    );
};

export default Home;

import React, { useState, useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import Select from 'react-select';
import moment from 'moment';
import './promotiontasks.scss';

import { useAuth0 } from '../../lib/auth';
import { REACT_APP_API_ENDPOINT } from '../../lib/getuserdetails';

import {
    getClustersByClient,
    getStoresByClient
} from '../../lib/apiWrappers';
import secureStorage from '../../lib/localStorage';
import constants from '../../lib/constants';
import {
    Button,
} from '../../components/button/button-container';

import { useHistory } from 'react-router-dom';

const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'grey' : 'white',
    }),
    control: (base: any, state: any) => ({
        ...base,
        'minHeight': '46px',
    }),
};

const PromotionTasks: React.FC = () => {
    const history = useHistory();
    const { getIdTokenClaims } = useAuth0();
    const [idToken, setIdToken] = useState<string | null>(null);
    const [tasksLoading, setTasksLoading] = useState(false);
    const [pendingTasks, setPendingTasks] = useState<any[]>([]);
    const [completedTasks, setCompletedTasks] = useState<any[]>([]);
    const [isAllowedToCreateTask, setIsAllowedToCreateTask] = useState(false);
    const [userRole, setUserRole] = useState<string | undefined>('associate');
    const [viewByStore, setViewByStore] = useState(true);
    const [viewByOptionSelected, setViewByOptionSelected] = useState<{
        value: string;
        label: string;
    }>({ value: '', label: 'Loading options...' });
    const [viewByOptions, setViewByOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const [viewTypeOptions, setViewTypeOptions] = useState<
        { value: string; label: string }[]
    >([
        {
            value: 'view_by_store',
            label: 'View By Store',
        },
        {
            value: 'view_by_cluster',
            label: 'View By Cluster',
        },
    ]);
    const [viewTypeOptionSelected, setViewTypeOptionSelected] = useState<{
        value: string;
        label: string;
    }>({ value: 'view_by_store', label: 'View By Store' });

    const handleClickChangeViewBy = (selectedOption: {
        value: string;
        label: string;
    }) => {
        if (selectedOption.value === 'view_by_store') {
            setViewByStore(true);
        } else {
            setViewByStore(false);
        }
        setViewTypeOptionSelected(selectedOption);
    };

    const getUserDetails = () => {
        const userToken = secureStorage.getItem(constants.ID_TOKEN);
        const userRole = secureStorage.getItem(constants.USER_ROLE);

        if (!userToken || !userRole) {
            return;
        }

        if (userRole === 'headquarter') {
            setIsAllowedToCreateTask(true);
        }
        setUserRole(userRole);
        setIdToken(userToken);
    };

    const getTasks = async () => {
        if (!idToken) {
            return null;
        }
        let tasksEndPoint = REACT_APP_API_ENDPOINT + '/tasks';

        if (userRole === 'headquarter') {
            if (viewByOptionSelected && viewByOptionSelected.value !== '') {
                if (viewByStore) {
                    tasksEndPoint = `${REACT_APP_API_ENDPOINT}/store/${viewByOptionSelected.value}/tasks`;
                } else {
                    tasksEndPoint = `${REACT_APP_API_ENDPOINT}/cluster/${viewByOptionSelected.value}/tasks`;
                }
            }
        }
        const response = await fetch(tasksEndPoint, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
        });
        const responseJson = await response.json();
        const userPendingTasks: any[] = [];
        const userCompletedTasks: any[] = [];
        if (responseJson.success) {
            for (let task of responseJson.data) {
                if (task.type === 'promotion') {
                    (task.status === 'completed' || task.status === 'cv_pending'
                        ? userCompletedTasks
                        : userPendingTasks
                    ).push(task);
                }
            }

            setPendingTasks(userPendingTasks);
            setCompletedTasks(userCompletedTasks);
        }
        setTasksLoading(false);
    };

    const loadOptions = async () => {
        if (!idToken) {
            return;
        }
        const claimsData = secureStorage.getItem(constants.CLAIMS);
        const clientId = claimsData ?.app_metadata ?._client ?? undefined;
        if (!clientId) {
            cogoToast.error("You are not associated with any company, please contact support");
            history.push("/home");
            return;
        }
        let viewOptions: any = [];
        if (viewByStore) {
            const stores = await getStoresByClient(clientId, idToken);
            stores.forEach(store => {
                if (store) {
                    viewOptions.push({
                        value: store._id,
                        label: store.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No stores could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        } else {
            const clusters = await getClustersByClient(clientId, idToken);
            clusters.forEach(cluster => {
                if (cluster) {
                    viewOptions.push({
                        value: cluster._id,
                        label: cluster.name
                    });
                }
            });
            if (viewOptions.length === 0) {
                cogoToast.error("No Cluster could be found associated with you. Please contact support.");
                setViewByStore(!viewByStore);
                return;
            }
        }
        setViewByOptions(viewOptions);
        setViewByOptionSelected(viewOptions[0]);
    };

    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        getTasks();
    }, [viewByOptionSelected]);
    useEffect(() => {
        loadOptions();
    }, [viewByStore]);
    useEffect(() => {
        loadOptions();
    }, [userRole]);
    useEffect(() => {
        getTasks();
    }, []);
    return (
        <div className='content content--tasks tasks'>
            {userRole === 'headquarter' ? (
                <div className="tasks__content tasks__viewBy">
                    <div className="tasks__viewBy__selector switch-field">
                        <input
                            type="radio"
                            id="radio-one"
                            name="switch-one"
                            value="View by store"
                            checked={viewByStore}
                            onChange={() => {
                                handleClickChangeViewBy(viewTypeOptions[0])
                            }}
                        />
                        <label htmlFor="radio-one">View by store</label>
                        <input
                            type="radio"
                            id="radio-two"
                            name="switch-one"
                            value="View by cluster"
                            checked={!(viewByStore)}
                            onChange={() => {
                                handleClickChangeViewBy(viewTypeOptions[1])
                            }}
                        />
                        <label htmlFor="radio-two">View by cluster</label>
                    </div>
                    <div className="tasks__viewBy__dropdown">
                        <Select
                            value={viewByOptionSelected}
                            styles={customStyles}
                            onChange={(selectedOption: any) => { setViewByOptionSelected(selectedOption) }}
                            options={viewByOptions}
                        />
                    </div>
                </div>
            ) : null}
            {userRole === 'manager' ? (
                <div className='tasks__content tasks__viewBy tasks__viewBy--manager'>
                    {/* <Select
                        value={viewTypeOptionSelected}
                        styles={customStyles}
                        onChange={(selectedOption: any) => {
                            handleClickChangeViewBy(selectedOption);
                        }}
                        options={viewTypeOptions}
                        className='tasks__viewBy--select'
                    /> */}
                    <Button
                        onClick={() =>
                            handleClickChangeViewBy(viewTypeOptions[0])
                        }
                        className={
                            viewTypeOptionSelected.value === 'view_by_store'
                                ? 'active'
                                : 'unactive'
                        }
                        buttonText='View by store'
                    />
                    <Button
                        onClick={() =>
                            handleClickChangeViewBy(viewTypeOptions[1])
                        }
                        className={
                            viewTypeOptionSelected.value === 'view_by_cluster'
                                ? 'active'
                                : 'unactive'
                        }
                        buttonText='View by cluster'
                    />
                    <div className='tasks__viewBy__dropdown'>
                        <Select
                            value={viewByOptionSelected}
                            styles={customStyles}
                            onChange={(selectedOption: any) => {
                                setViewByOptionSelected(selectedOption);
                            }}
                            options={viewByOptions}
                        />
                    </div>
                </div>
            ) : null}
            {tasksLoading ? (
                <div className='content content--tasks tasks tasks--loading'>
                    <div className='loadingSpinner'></div>
                </div>
            ) : (
                <div className='content__content tasks__content'>
                    <Tabs>
                        <TabList>
                            <Tab>
                                Pending
                                <span className='tasks__status__count'>
                                    {pendingTasks.length > 0
                                        ? ` (${pendingTasks.length})`
                                        : null}
                                </span>
                            </Tab>
                            <Tab>
                                Completed
                                <span className='tasks__status__count'>
                                    {completedTasks.length > 0
                                        ? ` (${completedTasks.length})`
                                        : null}
                                </span>
                            </Tab>
                        </TabList>

                        <TabPanel>
                            {pendingTasks.length === 0 ? (
                                <div className='tasks--empty'>
                                    No pending tasks
                                </div>
                            ) : (
                                pendingTasks.map((task) => {
                                    return (
                                        <div
                                            className='tasks__item'
                                            key={task._id}
                                        >
                                            <a
                                                className='tasks__item__link'
                                                onClick={() =>
                                                    history.push(
                                                        `/promotiontask/${task._id}`
                                                    )
                                                }
                                            >
                                                <div className='tasks__item__left'>
                                                    <div className='tasks__item__title'>
                                                        {task?.title}
                                                    </div>
                                                    <div className='tasks__item__description'>
                                                        {task?.due_date
                                                            ? 'Due by ' +
                                                              moment(
                                                                  task.due_date
                                                              ).format(
                                                                  'DD MMMM, YYYY h:mm A'
                                                              )
                                                            : ''}
                                                    </div>
                                                </div>
                                                <div className='tasks__item__right'></div>
                                            </a>
                                        </div>
                                    );
                                })
                            )}
                        </TabPanel>
                        <TabPanel>
                            {completedTasks.length === 0 ? (
                                <div className='tasks--empty'>
                                    No completed tasks
                                </div>
                            ) : (
                                completedTasks.map((task) => {
                                    return (
                                        <div
                                            className='tasks__item'
                                            key={task._id}
                                        >
                                            <a
                                                className='tasks__item__link'
                                                onClick={() =>
                                                    history.push(
                                                        `/promotiontask/${task._id}`
                                                    )
                                                }
                                            >
                                                <div className='tasks__item__left'>
                                                    <div className='tasks__item__tags'>
                                                        {task?.type ==
                                                        'audit' ? (
                                                            <div className='tasks__item__tag tasks__item__tag--audit'>
                                                                Audit
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    <div className='tasks__item__title'>
                                                        {task?.title}
                                                    </div>
                                                    <div className='tasks__item__description'>
                                                        {task?.description}
                                                    </div>
                                                </div>
                                                <div className='tasks__item__right'></div>
                                            </a>
                                        </div>
                                    );
                                })
                            )}
                        </TabPanel>
                    </Tabs>
                </div>
            )}

            {isAllowedToCreateTask ? (
                <div className='content__cta tasks__cta'>
                    <div className='tasks__cta__item'>
                        <button
                            className='tasks__cta__button btn--primary btn'
                            onClick={() => {
                                history.push('/createpromotiontask');
                            }}
                        >
                            Add New Promotion Task
                        </button>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    );
};

export default PromotionTasks;
